import { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { apiFetch } from '../util';
import { subscribeTo, PlayerContainer, AppStateContainer } from '../state';


function CheckPlayer({ player, app, interval }) {
	const [ intervalCount, resetInterval ] = useReducer(s => s + 1, 0);

	useEffect(() => {
		let timeoutRef = setTimeout(
			() => {
				if (player.loggedIn()) {
					apiFetch('player')
						.then(() => {
							resetInterval();
						})
						.catch((error) => {
							if (error.message === 'Network Error') {
								// ignore no internet errors
								resetInterval();
								return;
							}

							alert('Your session has expired. This could happen if you log in from some other device. Logging you off here...');
							player.logout().then(() => window.location.replace(app.url('/')));
						});
				}
			},
			interval
		);

		return () => {
			clearTimeout(timeoutRef);
		};
	}, [ intervalCount, player.loggedIn(), interval ]);

	return null;
}

CheckPlayer.propTypes = {
	interval: PropTypes.number,
	player: PropTypes.object
};

CheckPlayer.defaultProps = {
	interval: 60000
};

export default subscribeTo(
	{
		player: PlayerContainer,
		app: AppStateContainer
	},
	CheckPlayer
);
