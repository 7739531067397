import React from 'react';
import Helmet from 'react-helmet-async';

import { GameLog as GameLogComponent } from '../../component/profile';
import { VerticalPadder } from '../../component/styled';
import { siteTitle } from '../../util';


class GameLog extends React.PureComponent {

	render() {
		return (
			<>
				<Helmet>
					<title>{siteTitle('Player game log')}</title>
				</Helmet>
				<VerticalPadder>
					<GameLogComponent />
				</VerticalPadder>
			</>
		);
	}
}

export default GameLog;