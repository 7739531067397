import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Helmet from 'react-helmet-async';

import { ApiContent } from '.';
import { LoadingIndicator } from '../';
import { apiFetch, sortByDate } from '../../util';
import { ApiContent as ApiContentModel } from '../../models/api';
import { siteTitle } from '../../util';
import { subscribeTo, AppStateContainer } from '../../state';


const responseCache = {};
const preloading = {};

export function getCachedResponse(type) {
	if (!type || !responseCache.hasOwnProperty(type)) {
		return undefined;
	}

	return responseCache[type];
}

export function preloadType(type, callback, sortBy, contentTransformer) {
	const preloadedResponse = getCachedResponse(type);

	if (preloadedResponse) {
		callback(preloadedResponse, type);
		return;
	}

	const processCallback = () => {
		callback(getCachedResponse(type), type);
	};
	
	if (preloading.hasOwnProperty(type)) {
		preloading[type].then(processCallback);
	} else {
		preloading[type] = apiFetch('content/type/' + type)
			.catch(() => {
				delete preloading[type];
			})
			.then(res => {
				const processed = processResponse(res, type, sortBy, contentTransformer);

				responseCache[type] = processed;
			})
			.then(processCallback);
	}
}

function processResponse(res, type, sortBy, contentTransformer) {
	let response = null;

	// CloudFlaire response is a html page, so data will be string
	if (typeof res.data === 'string') {
		console.error('Invalid api response of type string for type ' + type);
	}

	if (res.data && Array.isArray(res.data.data) && res.data.data.length > 0) {
		response = new ApiContentModel(res.data.data[0], { sort: sortBy, contentTransformer });
	}

	return response;
}


function Content({ content }) {
	if (!content.content) {
		return null;
	}

	return (
		<ApiContent>{content.content}</ApiContent>
	);
}

class ApiContentLoader extends React.PureComponent {

	static propTypes = {
		type: PropTypes.string.isRequired,
		showContentTitle: PropTypes.bool,
		component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
		contentTitleComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
		sortBy: PropTypes.func,
		contentTransformer: PropTypes.func,
		setMeta: PropTypes.bool,
		canonicalUrl: PropTypes.string
	};

	static defaultProps = {
		showContentTitle: false,
		component: Content,
		sortBy: sortByDate,
		setMeta: false,
		contentTitleComponent: 'p',
		contentTransformer: undefined
	};

	state = {
		response: undefined,
		lastType: null
	};

	static getDerivedStateFromProps(props, state) {
		let returnedState = null;
		const { type, staticContext } = props;
		const { lastType } = state;

		if (lastType === null || type !== lastType) {
			let response = undefined;

			if (staticContext && staticContext.responses.contentLoader[type]) {
				response = staticContext.responses.contentLoader[type];
			} else {
				response = getCachedResponse(type);
			}

			returnedState = {
				lastType: type,
				response
			};
		}

		return returnedState;
	}

	constructor(props) {
		super(props);

		const { type } = props;

		const existingResponse = getCachedResponse(type);

		if (existingResponse) {
			this.state.response = existingResponse;
		} else {
			this.load(type);
		}
	}

	componentDidUpdate() {
		const { lastType } = this.state;
		const { type } = this.props;

		if (type !== lastType) {
			this.load();
		}
	}

	componentWillUnmount() {
		this._unmounted = true;
	}

	render() {
		const { response } = this.state;

		if (response === undefined) {
			return (
				<LoadingIndicator />
			);
		}

		if (!response) {
			return null;
		}

		const {
			showContentTitle, component: Component,
			type, setMeta, contentTitleComponent, canonicalUrl,
			app, ...rest
		} = this.props;

		return (
			<>
				{
					setMeta &&
					<Helmet>
						{response.meta.title && <title>{siteTitle(response.meta.title)}</title>}
						{response.meta.title && <meta name="description" content={response.meta.description} />}
						{canonicalUrl && <link rel="canonical" href={app.canonicalize(canonicalUrl)} />}
					</Helmet>
				}
				{showContentTitle && response.title && <Typography variant="h6" component={contentTitleComponent} color="primary" gutterBottom>{response.title}</Typography>}
				<Component content={response} setMeta={setMeta} canonicalUrl={canonicalUrl} {...rest} />
			</>
		);
	}

	load(forceType) {
		const { lastType, response } = this.state;
		const { staticContext, type } = this.props;

		if (!type || (staticContext && staticContext.secondRun)) {
			return;
		}

		if (!forceType && (!lastType || response !== undefined)) {
			return;
		}

		const request = apiFetch('content/type/' + (forceType || type))
			.then(this.processResponse)
			.catch((error) => {
				this.processResponse(error.response);
			});

		if (staticContext && staticContext.fetching) {
			staticContext.fetching.push(request);
		}
	}

	processResponse = (requestResponse) => {
		const { lastType } = this.state;
		const { type, sortBy, contentTransformer } = this.props;

		if (this._unmounted || lastType !== type || !requestResponse) {
			return;
		}

		const response = processResponse(requestResponse, lastType, sortBy, contentTransformer);
		const { staticContext } = this.props;

		if (staticContext && staticContext.responses) {
			staticContext.responses.contentLoader[lastType] = response;
		} else {
			responseCache[lastType] = response;

			this.setState({
				response
			});
		}
	}

}

export default subscribeTo(
	{
		app: AppStateContainer
	},
	withRouter(ApiContentLoader)
);