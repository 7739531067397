import React from 'react';
import Helmet from 'react-helmet-async';

import { Restrictions as RestrictionsComponent } from '../../component/profile';
import { VerticalPadder } from '../../component/styled';
import { siteTitle } from '../../util';


function Restrictions() {
	return (
		<>
			<Helmet>
				<title>{siteTitle('Restrictions')}</title>
			</Helmet>
			<VerticalPadder>
				<RestrictionsComponent />
			</VerticalPadder>
		</>
	);
}

export default Restrictions;