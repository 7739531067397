import React from 'react';
import Helmet from 'react-helmet-async';

import { BonusHistory as BonusHistoryComponent } from '../../component/profile';
import { VerticalPadder } from '../../component/styled';
import { siteTitle } from '../../util';


function BonusHistory() {
	return (
		<>
			<Helmet>
				<title>{siteTitle('Bonus history')}</title>
			</Helmet>
			<VerticalPadder>
				<BonusHistoryComponent />
			</VerticalPadder>
		</>
	);
}

export default BonusHistory;