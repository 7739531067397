import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
	TextField, Typography, Paper, InputAdornment, IconButton, Button
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import Fuse from 'fuse.js';

import { apiFetch, getFormData } from '../util';
import { VerticalPadder } from './styled';
import { processGamesResponse } from '../models/api';
import { subscribeTo, AppStateContainer } from '../state';
import { GameGrid, Link } from '.';
import { preloadType } from './api/ApiContentLoader';
import { ListItem } from './api/article';
import { Spacer } from './styled';


class Search extends React.PureComponent {

	static propTypes = {
		query: PropTypes.string
	};

	static defaultProps = {
		query: ''
	};

	state = {
		emptyInput: false,
		searchingGames: false,
		gameResults: undefined,
		newsResults: undefined,
		blogResults: undefined
	};

	searchField = React.createRef();
	blogIndex = null;

	componentDidMount() {
		this.doSearch();

		const { auOnly } = this.props.app.state;

		const blogType = auOnly ? 'blog_category_news' : 'au-blog-new';

		preloadType(
			blogType,
			res => {
				this.blogIndex = new Fuse(res.children, {
					keys: [
						{
							name: 'title',
							weight: .3
						},
						{
							name: 'summary',
							weight: .2
						},
						{
							name: 'content',
							weight: .5
						}
					]
				});

				this.doSearch(true);
			}
		);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.query !== this.props.query) {
			this.doSearch();
		}
	}

	componentWillUnmount() {
		this._unmounted = true;
		this.blogIndex = undefined;
	}

	render() {
		const { query } = this.props;
		const { emptyInput } = this.state;

		return (
			<>
				<Paper>
					<VerticalPadder
						left={2}
						right={2}
					>
						<SearchForm
							onSubmit={this.handleSubmit}
						>
							<TextField
								inputRef={this.searchField}
								defaultValue={query}
								placeholder="Search..."
								name="query"
								helperText={emptyInput ? 'Please specify a search term.' : ''}
								error={emptyInput}
								variant="outlined"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="Start search"
												type="submit"
											>
												<SearchIcon />
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</SearchForm>
						{this.renderGames()}
						{this.renderBlogs()}
					</VerticalPadder>
				</Paper>
			</>
		);
	}

	renderGames() {
		const { gameResults, searchingGames } = this.state;

		if (gameResults === undefined && !searchingGames) {
			return null;
		}

		let content = null;

		if (Array.isArray(gameResults)) {
			content = (
				<VerticalPadder>
					<Typography variant="subtitle1" component="p" align="center">Game results</Typography>
					<GameGrid games={gameResults} loading={searchingGames} />
				</VerticalPadder>
			);
		} else {
			if (searchingGames) {
				content = (
					<Typography key="searching" variant="subtitle1" component="p" align="center">Searching for games...</Typography>
				);
			} else {
				content = (
					<Typography key="failed" variant="subtitle1" component="p" align="center">Search failed, please retry.</Typography>
				);
			}
		}

		return (
			<VerticalPadder>
				{content}
			</VerticalPadder>
		);
	}

	renderBlogs() {
		const { blogResults } = this.state;
		const { auOnly } = this.props.app.state;

		if (blogResults === undefined) {
			return null;
		}

		let content = null;

		if (blogResults.length === 0) {
			content = (
				<Spacer>
					<Typography variant="subtitle1" component="p" color="textSecondary">
						No blogs to display...
					</Typography>
				</Spacer>
			);
		} else {
			content = (
				<>
					{blogResults.map(blog => {
						const detailsUrl = `/${auOnly ? 'blog' : 'blogs'}/${blog.slug}`;

						return (
							<ListItem
								key={blog.slug}
								item={blog}
								detailsUrl={detailsUrl}
								imageRatioPercentage={44.75}
								actions={(
									<Button
										variant="contained"
										component={Link}
										to={detailsUrl}
										color="primary"
									>
										Continue reading
									</Button>
								)}
							/>
						);
					})}
				</>
			);
		}

		return (
			<VerticalPadder>
				<Typography variant="subtitle1" component="p" align="center">Blog results</Typography>
				{content}
			</VerticalPadder>
		);
	}

	handleSubmit = (ev) => {
		ev.preventDefault();

		const formData = getFormData(ev.currentTarget);
		const newState = {
			emptyInput: false,
			searchingGames: false,
			newsResults: undefined
		};

		if (!formData.query) {
			newState.gameResults = undefined;
			newState.emptyInput = true;
		}

		this.setState(newState, () => {
			const { emptyInput } = this.state;

			if (!emptyInput) {
				const { onSearch } = this.props;

				if (onSearch) {
					const { value } = this.searchField.current;
					onSearch(value);
				}
			}
		});
	};

	doSearch = (secondaryTrigger = false) => {
		const { query } = this.props;

		if (this._unmounted || !query) {
			return;
		}

		if (!secondaryTrigger) {
			if (this.gamesRequestSource) {
				this.gamesRequestSource.cancel();
			}
	
			this.gamesRequestSource = axios.CancelToken.source();

			if (!this.state.searchingGames) {
				this.setState({ searchingGames: true }, () => {
					const encodedValue = encodeURIComponent(query);

					apiFetch(
						`games/search/${encodedValue}`,
						{
							cancelToken: this.gamesRequestSource.token
						}
					)
						.then(this.processGamesResponse)
						.catch(error => {
							if (axios.isCancel(error)) {
								return;
							}

							this.processGamesResponse(error.response);
						});
				});
			}
		}

		if (this.blogIndex) {
			this.setState({ blogResults: this.blogIndex.search(query) });
		}
	};

	processGamesResponse = response => {
		if (this._unmounted) {
			return;
		}

		const newState = {
			gameResults: null,
			searchingGames: false
		};

		if (
			response &&
			response.data &&
			response.data.data &&
			Array.isArray(response.data.data.games)
		) {
			newState.gameResults = processGamesResponse(response.data.data.games, this.props.app.state.isMobile);
		}

		this.setState(newState);
	};

}

const SearchForm = styled.form`
	display: flex;
	justify-content: center;
`;


export default subscribeTo(
	{
		app: AppStateContainer
	},
	Search
);