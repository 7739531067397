import React, { useEffect } from 'react';


function ApgSeal() {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://e6f5b9e6-5a60-4d9d-86cb-c0a93dee490b.snippet.antillephone.com/apg-seal.js';

    document.getElementsByTagName('head')[0].appendChild(script);

    return () => {
      script.remove();
    };
  }, []);

  return (
    <div
      id="apg-e6f5b9e6-5a60-4d9d-86cb-c0a93dee490b"
      data-apg-seal-id="e6f5b9e6-5a60-4d9d-86cb-c0a93dee490b"
      data-apg-image-size="128"
      data-apg-image-type="basic-small"
    />
  );
}

export default ApgSeal;