import { BaseContainer } from '.';
import { apiFetch } from '../util';


class SiteStatsContainer extends BaseContainer {

	static initialState() {
		return {
			initialized: false,
			playersCurentlyOnline: undefined,
			topWinYesterday: undefined,
			topWinThisWeek: undefined,
			topWinLastWeek: undefined
		};

	}

	static fetch() {
		return apiFetch('/casinoLatestData');
	}

	loaded() {
		return this.state.deposit !== undefined;
	}

	errorResponse() {
		return this.state.deposit === null;
	}

	valuesLoaded() {
		return this.loaded() && !this.errorResponse();
	}

	handleResponse = response => {
		const newState = {
			initialized: true,
			playersCurentlyOnline: 0
		};

		const { data } = response;

		if (Array.isArray(data)) {
			for (const datum of data) {
				switch (datum.key) {
					case 'players_curently_online':
						newState.playersCurentlyOnline = datum.count + 25;
						break;

					case 'top_win_yesterday':
						newState.topWinYesterday = datum.players;
						break;

					case 'top_win_this_week':
						newState.topWinThisWeek = datum.players;
						break;

					case 'top_win_last_week':
						newState.topWinLastWeek = datum.players;
						break;

					default:
				}
			}
		}

		if (newState.playersCurentlyOnline === 0 ) {
			newState.playersCurentlyOnline = Math.ceil(Math.random() * 5);
		}

		this.setState(newState);
	};

}

export default SiteStatsContainer;