import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { HowToPlay } from './Help/';
import { ApiContentLoader } from '../component/api';
import { MaxWidth, VerticalPadder, PageWidthBoundary } from '../component/styled';
import { ScrollTopOnMount } from '../component';
import { AboutUs, Affiliates, Promotions } from '.';
import { subscribeTo, AppStateContainer } from '../state';
import { isAuOrNz, inBrowser } from '../util';


const IndexRedirect = () => <Redirect to="/" />;
const Faq = subscribeTo({ app: AppStateContainer }, ({ app }) => <PageWidthBoundary><VerticalPadder><ScrollTopOnMount /><MaxWidth><ApiContentLoader contentTitleComponent="h1" type={app.state.auOnly ? 'fa-new' : 'fa-new-v1'} canonicalUrl="content/faq" setMeta showContentTitle /></MaxWidth></VerticalPadder></PageWidthBoundary>);
const PrivacyPolicy = subscribeTo({ app: AppStateContainer }, ({ app }) => <PageWidthBoundary><VerticalPadder><ScrollTopOnMount /><MaxWidth><ApiContentLoader contentTitleComponent="h1" type={app.state.auOnly ? 'footer,register_privacy_policy' : 'privacy-policy-v1'} canonicalUrl="content/privacy-policy" setMeta showContentTitle /></MaxWidth></VerticalPadder></PageWidthBoundary>);
const TermsAndConditions = subscribeTo({ app: AppStateContainer }, ({ app }) => {
	if (inBrowser && app.state.auOnly && !isAuOrNz(app.state.ipCountry)) {
		window.location.href = '/content/terms-and-conditions';
		return null;
	}

	return (
		<PageWidthBoundary><VerticalPadder><ScrollTopOnMount /><MaxWidth><ApiContentLoader contentTitleComponent="h1" type={app.state.auOnly ? 'footer,register_terms_and_conditions' : 'terms-v1'} canonicalUrl="content/terms-and-conditions" setMeta showContentTitle /></MaxWidth></VerticalPadder></PageWidthBoundary>
	);
});
const BitcoinDeposits = () => <PageWidthBoundary><VerticalPadder><ScrollTopOnMount /><MaxWidth><ApiContentLoader contentTitleComponent="h1" type="btc-dep-seo" canonicalUrl="content/bitcoin-deposits" setMeta showContentTitle /></MaxWidth></VerticalPadder></PageWidthBoundary>;
const ResponsibleGaming = () => <PageWidthBoundary><VerticalPadder><ScrollTopOnMount /><MaxWidth><ApiContentLoader contentTitleComponent="h1" type="responsible-gambling" canonicalUrl="content/responsible-gambling" setMeta showContentTitle /></MaxWidth></VerticalPadder></PageWidthBoundary>;

function Content() {
	return (
		<Switch>
			<Route path="/content/about-us" component={AboutUs} />
			<Route path="/content/affiliates" component={Affiliates} />
			<Route path="/content/how_to_play/:article?" component={HowToPlay} />
			<Route path="/content/faq" component={Faq} />
			<Route path="/content/privacy-policy" component={PrivacyPolicy} />
			<Route path="/content/terms-and-conditions" component={TermsAndConditions} />
			<Route path="/content/bitcoin-deposits" component={BitcoinDeposits} />
			<Route path="/content/responsible-gambling" component={ResponsibleGaming} />
			<Route path="/content/promotionsb/:promotion?" component={Promotions} />
			<Route path="/content" component={IndexRedirect} />
		</Switch>
	);
}

export default Content;
