import React from 'react';
import { Typography, Grid, TextField, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { GamesFilter, ScrollTopOnMount } from '../component';
import { VerticalPadder, PageWidthBoundary } from '../component/styled';
import { ApiCarousel, ApiContentLoader, ApiSeoContent, SecondaryCarousel } from '../component/api';
import { getFormData, sortByPosition } from '../util';
import { AppStateContainer, subscribeTo } from '../state';


const categories = [
	'new',
	'video-slots',
	'table-games',
	'video-poker',
	'jackpot',
	'scratch-cards',
	'video-bingo'
];

class Casino extends React.PureComponent {

	render() {
		const { appState } = this.props;

		return (
			<>
				<ApiContentLoader
					type={appState.state.auOnly ? 'casino-hero-v2' : 'hero-slider-casino-new'}
					component={ApiCarousel}
					sortBy={sortByPosition}
					bgColor="#1a262c"
				/>
				<PageWidthBoundary>
					<ScrollTopOnMount />
					<VerticalPadder>
						<ApiContentLoader
							type="casino-content_slider"
							component={SecondaryCarousel}
						/>
					</VerticalPadder>
					<VerticalPadder>
						<Grid container spacing={0} justify="space-between">
							<Grid item>
								<Typography variant='h5' component="p" gutterBottom>Casino</Typography>
							</Grid>
							<Grid item>
								<form
									onSubmit={this.handleSubmit}
								>
									<TextField
										defaultValue=""
										placeholder="Search games"
										name="query"
										helperText={null}
										variant="standard"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="Start search"
														type="submit"
													>
														<SearchIcon />
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</form>
							</Grid>
						</Grid>
						<GamesFilter
							providers={appState.state.casinoGameProviders}
							categories={categories}
						/>
					</VerticalPadder>
					<VerticalPadder>
						<ApiContentLoader
							type={appState.state.auOnly ? 'casino-page-seo-v2' : 'casino-page-seo-new'}
							component={ApiSeoContent}
							setMeta
							canonicalUrl="casino"
						/>
					</VerticalPadder>
				</PageWidthBoundary>
			</>
		);
	}

	handleSubmit = (ev) => {
		ev.preventDefault();

		const { query } = getFormData(ev.target);

		if (!query) {
			return;
		}

		const { history } = this.props;

		history.push(`/search?q=${encodeURIComponent(query)}`);
	};
}

export default subscribeTo(
	{
		appState: AppStateContainer
	},
	Casino
);
