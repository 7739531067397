import React from 'react';

import { PaymentProcess } from '.';


function Withdraw() {
	return (
		<PaymentProcess methodType="withdrawal" />
	)
}

export default Withdraw;