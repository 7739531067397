import React from 'react';

import { subscribeTo, PlayerContainer } from '../../state';
import { NumberField } from '.';
import { currencyAgents, defaultCurrency } from '../../data';


class CurrencyField extends React.PureComponent {

	render() {
		let currencySymbol = currencyAgents[defaultCurrency].symbol;

		if (this.props.player.loggedIn()) {
			const { currencyCode } = this.props.player.state.player;
			currencySymbol = currencyAgents[currencyCode].symbol;
		}

		return (
			<NumberField
				{...this.props}
				inputProps={{
					thousandSeparator: ',',
					decimalScale: 2,
					fixedDecimalScale: true,
					allowNegative: false,
					prefix: `${currencySymbol} `,
					type: 'tel'
				}}
			/>
		);
	}
}

export default subscribeTo(
	{
		player: PlayerContainer
	},
	CurrencyField
);