import React, { useEffect, useReducer, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { SnackbarContent, Typography, IconButton } from '@material-ui/core';
import { default as CloseIcon } from '@material-ui/icons/Close';

import { apiFetch } from '../util';
import { subscribeTo, PlayerContainer } from '../state';


const StyledSnackbarContent = styled(SnackbarContent)`
	background: ${p => p.theme.mui.palette.background.notification};
	color: ${p => p.theme.mui.palette.action.active};
	font-size: ${p => p.theme.pxToRem(14)};
`;


function ApiMessages({ player, interval }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const [ timeoutCount, resetTimeout ] = useReducer(s => s + 1, 0);

	const checkMessages = useCallback(() => {
		if (!player.loggedIn()) {
			return;
		}

		apiFetch('player/messages')
			.then(response => {
				if (!response.data) {
					return;
				}

				const { info, data } = response.data;

				if (!info || !data) {
					return;
				}

				if (info.success && Array.isArray(data)) {
					for (const item of data) {
						const isError = item.type === 'error';

						enqueueSnackbar('', {
							variant: isError ? 'error' : 'info',
							children: snackKey => (
								<StyledSnackbarContent
									message={
										<Typography
											color={isError ? 'error' : 'inherit'}
											variant="inherit"
											component="div"
											align="center"
										>
											<div dangerouslySetInnerHTML={{ __html: item.message }} />
										</Typography>}
									action={
										<IconButton
											aria-label="Close"
											color="inherit"
											onClick={() => closeSnackbar(snackKey)}
										>
											<CloseIcon />
										</IconButton>
									}
								/>
							)
						});
					}
				}

				resetTimeout();
			})
			.catch((error) => {
				if (error.message === 'Network Error') {
					// ignore no internet errors
					resetTimeout();
					return;
				}
			});
	}, [ player.loggedIn() ]);

	useEffect(() => {
		let timeoutRef = setTimeout(checkMessages, interval);

		return () => {
			clearTimeout(timeoutRef);
		};
	}, [ checkMessages, timeoutCount, interval ]);

	useEffect(() => {
		checkMessages();
	}, []);

	return null;
}

ApiMessages.propTypes = {
	interval: PropTypes.number,
	player: PropTypes.object
};

ApiMessages.defaultProps = {
	interval: 30000
};

export default subscribeTo(
	{
		player: PlayerContainer
	},
	ApiMessages
);