import React from 'react';
import Loadable from 'react-loadable';

import { LoadingIndicator } from '../component';

const LoadableComponent = Loadable({
	loader: () => import('./payment'),
	loading: LoadingIndicator,
});

export default function Payment(props) {
	return (
		<LoadableComponent {...props} />
	);
}