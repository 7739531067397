import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import {
	Typography, Grid, Button, TextField, Tooltip
} from '@material-ui/core';

import { Form } from '..';
import { GridContainer, MaxWidth } from '../styled';
import {
	apiFetch, processApiFields,
	validatorFromApiFields, removeApiFieldScripts, inBrowser
} from '../../util';
import { ApiFormFields } from '../api';

class PaymentForm extends React.PureComponent {

	static externalScriptIndex = 0;

	static propTypes = {
		method: PropTypes.object.isRequired,
		methodType: PropTypes.oneOf([ 'deposit', 'withdrawal' ]).isRequired,
		onSubmit: PropTypes.func
	};

	state = {
		formError: undefined,
		submitting: false,
		wallet: null,
		walletCopyTooltip: false,
		walletCopyTagTooltip: false,
		iframeRedirect: null,
		postForm: undefined
	};

	scripts = [];
	validator = null;

	constructor(props) {
		super(props);

		const { fields } = props.method.form;

		const validator = validatorFromApiFields(fields);
		this.scripts = validator.scripts;
		this.validator = validator.instance;
		this.iframeSubmitted = false;

		this.walletInputRef = React.createRef();

		this.walletTagInputRef = React.createRef();

		window.addEventListener('message', this.messageReceived, false);
	}

	componentWillUnmount() {
		this._unmounted = true;

		removeApiFieldScripts(this.scripts);

		window.removeEventListener('message', this.messageReceived, false);
	}

	messageReceived = (ev) => {
		let json = undefined;

		if (typeof ev.data === 'string') {
			try {
				json = JSON.parse(ev.data);
			} catch (ex) {}
		}

		if (json && json.action === 'close') {
			this.closeWindow();
		}
	};

	render() {
		const { method } = this.props;
		const { iframeRedirect, postForm } = this.state;

		if (iframeRedirect) {
			const { width, height, url, method, parameters } = iframeRedirect;

			return (
				<>
					<iframe title="Payment" name="redirectTo" height={height} width={width}></iframe>
					<form
						action={url}
						method={method}
						target="redirectTo"
						ref={form => {
							if (this.iframeSubmitted) {
								return;
							}

							form.submit();
							this.iframeSubmitted = true;
						}}
					>
						{parameters && Object.keys(parameters).map(key => <input key={key} type="hidden" name={key} value={parameters[key]} />)}
					</form>
				</>
			);
		}

		if (postForm) {
			const { url, parameters } = postForm;
			return (
				<form
					action={url}
					method="POST"
					ref={formEl => {
						formEl.submit();
					}}
				>
					{parameters && Object.keys(parameters).map(key => <input key={key} type="hidden" name={key} value={parameters[key]} />)}
				</form>
			);
		}

		return (
			<MainWrapper>
				<Header>
					<Typography variant="caption" component="p">
						<img
							src={process.env.REACT_APP_API_IMAGE_ROOT + method.image}
							aria-label={method.name}
							alt=""
							width="80"
							height="50"
						/>
						<WithAnchor component="span" dangerouslySetInnerHTML={{ __html: method.text }} />
					</Typography>
				</Header>
				{this.renderContent()}
			</MainWrapper>
		);
	}

	renderContent() {
		const { wallet, walletCopyTooltip, walletCopyTagTooltip } = this.state;

		if (wallet) {
			const { code, isCrypto } = this.props.method;
			const { message, address, destinationTag } = wallet;

			let network, bottomMessage;

			if (isCrypto) {
				switch (code) {
					case 'alphapo_btc':
						network = 'Bitcoin';
						bottomMessage = 'This is a BTC address, please send only BTC to this address via Bitcoin blockchain (Bitcoin). Sending any other currency than BTC or via any other network (such as Binance - BEP-20, Ethereum - ERC-20, SegWit etc.) will result in the loss of the funds.';
						break;

					case 'alphapo_eth':
						network = 'ERC20';
						bottomMessage = 'This is a USDT address, please send only USDT to this address via Ethereum blockchain (ERC20). Sending any other currency than ETH or via any other blockchain (such as Binance - BEP-20, Avalanche - C-Chain, Polygon, etc.) will result in the loss of the funds.';
						break;

					case 'coinpayments_usdt.erc20':
						network = 'ERC20';
						bottomMessage = 'This is a USDT address, please send only USDT to this address via Ethereum network (ERC20). Sending any other currency than ETH or via any other network (such as Binance - BEP-20, Avalanche - C-Chain, Polygon, etc.) will result in the loss of the funds.';
						break;

					case 'alphapo_ltc':
						network = 'Litecoin';
						bottomMessage = 'This is a LTC address, please send only LTC to this address via Litecoin\'s native mainnet (Litecoin). Sending any other currency than LTC or via any other network (such as Binance - BEP-20, Ethereum - ERC-20, etc.) will result in the loss of the funds.';
						break;

					case 'alphapo_xrp':
						network = 'Ripple';
						bottomMessage = 'This is a XRP address, please send only XRP to this address via Ripple\'s native mainnet (Ripple), making sure that the Memo/Destination Tag maches the one displayed on this screen. Sending any other currency than BNB, mistyping the memo or using a different network (such as Binance - BEP-20, Ethereum - ERC-20, etc.) will result in the loss of the funds.';
						break;

					case 'coinpayments_dash':
						network = '';
						bottomMessage = '';
						break;

					case 'coinpayments_doge':
						network = '';
						bottomMessage = '';
						break;

					case 'coinpayments_bnb':
						network = 'BEP2';
						bottomMessage = 'This is a BNB address, please send only BNB to this address via BNB Beacon Chain (BEP2), making sure that the Memo/Destination Tag matches the one displayed on this screen. Sending any other currency than BNB, mistyping the memo or using a different network (such as Binance - BEP-20) will result in the loss of the funds.';
						break;

					default:
				}
			}

			return (
				<MaxWidth>
					<Typography component="div" align="center" paragraph>
						<img
							alt="QR code"
							src={`https://chart.googleapis.com/chart?cht=qr&chl=${address}&choe=UTF-8&chs=150x150`}
						/>
					</Typography>
					{network && <Typography variant="h5" align="center">
						Network: {network}
					</Typography>}
					<Typography component="div" paragraph align="center">
						{message}
					</Typography>
					<Typography component="div" align="center" paragraph>
						<Tooltip
							open={walletCopyTooltip}
							title="Address copied!"
							aria-label="Address copied!"
							placement="top"
						>
							<AddressField
								label="Address"
								defaultValue={address}
								onClick={this.handleTextFieldClick}
								inputRef={this.walletInputRef}
								InputProps={{
									classes: {
										input: 'addressInput'
									},
									readOnly: true
								}}
								fullWidth
							/>
						</Tooltip>
						<Typography component="p" align="center" variant="caption" paragraph>
							Please copy the address manually if the button doesn't work.
						</Typography>
					</Typography>
					<Grid container justify="center" spacing={16}>
						<Grid item>
							<Button
								variant="contained"
								onClick={this.addressToClipboard}
								color="primary"
							>
								Copy address
							</Button>
						</Grid>
					</Grid>
					{
						destinationTag &&
						<>
							<Typography component="div" align="center" paragraph>
								<Tooltip
									open={walletCopyTagTooltip}
									title="Destination tag copied!"
									aria-label="Destination tag copied!"
									placement="top"
								>
									<AddressField
										label="Destination tag"
										defaultValue={destinationTag}
										onClick={this.handleTagTextFieldClick}
										inputRef={this.walletTagInputRef}
										InputProps={{
											classes: {
												input: 'addressInput'
											},
											readOnly: true
										}}
										fullWidth
									/>
								</Tooltip>
								<Typography component="p" align="center" variant="caption" paragraph>
									Please copy the destination tag manually if the button doesn't work.
								</Typography>
							</Typography>
							<Grid container justify="center" spacing={16}>
								<Grid item>
									<Button
										variant="contained"
										onClick={this.destinationTagToClipboard}
										color="primary"
									>
										Copy destination tag
									</Button>
								</Grid>
							</Grid>
						</>
					}
					<p>&nbsp;</p>
					<Grid container justify="center" spacing={16}>
						<Grid item>
							<Button
								variant="contained"
								onClick={this.closeWindow}
								color="secondary"
							>
								Close
							</Button>
						</Grid>
					</Grid>
					<p>&nbsp;</p>
					{bottomMessage && <Typography component="p" align="center" paragraph>
						{bottomMessage}
					</Typography>}
				</MaxWidth>
			);
		}

		const { method, methodType } = this.props;
		const { formError, submitting } = this.state;

		return (
			<Form
				onSubmit={this.handleSubmit}
				validator={this.validator}
				submitting={submitting}
			>
				{(formState) => (
					<Form.FieldStateProvider
						center
						validator={this.validator}
						formState={formState}
						textFieldProps={{
							margin: 'dense',
							fullWidth: true
						}}
					>
						{(fieldStates) => (
							<GridContainer spacing={16} justify="center">
								<ApiFormFields
									fieldStates={fieldStates}
									fields={method.form.fields}
									GridProps={{
										xs: 12,
										sm: 6
									}}
								/>
								<SubmitContainer item container direction="column" sm={12} justify="center">
									{
										formError &&
										<Typography
											align="center"
											color="error"
											component="p"
											variant="caption"
										>
											{formError}
										</Typography>
									}
									<Button
										type="submit"
										variant="contained"
										color="primary"
										disabled={!!formState.submitting}
									>
										{formState.submitting ? 'Processing...' : (methodType === 'deposit' ? 'Deposit' : 'Withdraw')}
									</Button>
								</SubmitContainer>
							</GridContainer>
						)}
					</Form.FieldStateProvider>
				)}
			</Form>
		);
	}

	handleSubmit = (data) => {
		const { onSubmit } = this.props;

		if (onSubmit) {
			onSubmit();
		}

		this.setState({
			formError: undefined
		}, () => {
			try {
				data = processApiFields(this.props.method.form.fields, data);
			} catch (ex) {
				this.setState({
					formError: ex.message
				});
				return;
			}

			const { method, methodType } = this.props;

			this.setState({
				formError: undefined,
				submitting: true
			}, () => {
				apiFetch.post(
					`player/cashier/payment/${method.processor}/${method.code}/${methodType === 'deposit' ? 'deposit' : 'withdrawal'}`,
					{ data }
				)
					.then(this.handleResponse)
					.catch(error => this.handleResponse(error.response));
			});
		});
	};

	handleResponse = response => {
		if (this._unmounted) {
			return;
		}

		this.setState({
			submitting: false
		});

		if (
			!response ||
			!response.data ||
			!response.data.data ||
			!response.data.info
		) {
			this.setState({ formError: 'We had a problem while processing your transfer. Please try again.' });
			return;
		}

		if (!response.data.info.success) {
			const message = response.data.data.messages || response.data.info.message;

			if (message) {
				this.setState({ formError: message });

				return;
			}
		}

		switch (response.data.data.status) {
			case 'redirect':
				const {
					container, url, parameters, method, html
				} = response.data.data.redirect;
				let {
					height, width
				} = response.data.data.redirect;

				if (container === 'iframe') {
					height = parseInt(height, 10);
					width = parseInt(width, 10);

					if (isNaN(height) || height < 600) {
						height = 600;
					}

					if (this.props.method.code === 'mifinity' && height < 1700) {
						height = 1700;
					}

					if (isNaN(width) || width < 600) {
						width = 600;
					}

					this.setState({
						iframeRedirect: {
							height,
							width,
							url,
							parameters,
							method
						}
					});

					if (inBrowser) {
						window.resizeTo(width + 60, height + 150);
					}
				} else {
					if (url) {
						if (method === 'POST') {
							this.setState({
								postForm: {
									url: url,
									parameters: parameters || {}
								}
							});
						} else {
							let urlParams = '';

							if (parameters) {
								for (const key of Object.keys(parameters)) {
									urlParams += encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key]) + '&';
								}
							}

							window.location.replace(url + (urlParams ? '?' + urlParams : ''));
						}
					} else if (html) {
						document.open();
						document.write(html);
						document.close();
					}
				}
				break;

			case 'show_wallet':
				let wallet = null;

				if (
					typeof response.data.data.crypto_wallet === 'object' &&
					response.data.data.crypto_wallet.address &&
					typeof response.data.data.crypto_wallet.exchange_rate === 'object'
				) {
					const { currFromStr, currToStr } = response.data.data.crypto_wallet.exchange_rate;

					wallet = {
						message: `Current exchange rate: ${currFromStr} = ${currToStr}`,
						address: response.data.data.crypto_wallet.address,
						destinationTag: response.data.data.crypto_wallet.destinationTag
					};
				}

				this.setState({ wallet });
				break;

			case 'complete':
				this.closeWindow();
				break;

			default:
				// TODO sentry error, unknown payment method response
		}
	};

	addressToClipboard = () => {
		const { wallet } = this.state;
		const { clipboard } = navigator;

		if (wallet && clipboard && clipboard.writeText) {
			const { address } = wallet;

			clipboard.writeText(address).then(() => {
				this.setState({
					walletCopyTooltip: true
				}, () => {
					setTimeout(() => {
						this.setState({
							walletCopyTooltip: false
						});
					}, 3000);
				});
			});
		}
	};

	destinationTagToClipboard = () => {
		const { wallet } = this.state;
		const { clipboard } = navigator;

		if (wallet && clipboard && clipboard.writeText) {
			const { destinationTag } = wallet;

			clipboard.writeText(destinationTag).then(() => {
				this.setState({
					walletCopyTagTooltip: true
				}, () => {
					setTimeout(() => {
						this.setState({
							walletCopyTagTooltip: false
						});
					}, 3000);
				});
			});
		}
	};

	handleTextFieldClick = () => {
		if (this.walletInputRef.current) {
			const { current: input } = this.walletInputRef;
			this.addressToClipboard();

			input.setSelectionRange(0, input.value.length);
		}
	};

	handleTagTextFieldClick = () => {
		if (this.walletInputRef.current) {
			const { current: input } = this.walletTagInputRef;
			this.destinationTagToClipboard();

			input.setSelectionRange(0, input.value.length);
		}
	};

	closeWindow = () => {
		window.close();
	};

}

const MainWrapper = styled.div`
	max-width: ${p => p.theme.spacing(100)}px;
	margin: 0 auto;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${p => p.theme.spacing(2)}px;
	padding: 0 ${p => p.theme.spacing(1)}px;
	justify-content: center;

	& > p {
		padding: 0 0 0 ${p => p.theme.spacing(12)}px;
		position: relative;
		min-height: 50px;
	}

	& > p > img {
		left: 0;
		right: auto;
		top: 50%;
		position: absolute;
		width: 80px;
		height: 50px;
		transform: translateY(-50%);
	}
`;

const SubmitContainer = styled(Grid)`
	margin-top: ${p => p.theme.spacing(2)}px;

	& > p {
		margin-bottom: ${p => p.theme.spacing()}px;
	}
`;

const AddressField = styled(TextField)`
	max-width: 450px;

	.addressInput {
		font-size: ${p => p.theme.pxToRem(15)};
		text-align: center;
	}

	${p => p.theme.mui.breakpoints.down('xs')} {
		.addressInput {
			font-size: ${p => p.theme.pxToRem(12)};
		}
	}

	@media (max-width: 350px) {
		.addressInput {
			font-size: ${p => p.theme.pxToRem(10)};
		}
	}
`;


const WithAnchor = styled(Typography)`
	a, a:visited {
		color: #fff;
	}
`;


export default withRouter(PaymentForm);