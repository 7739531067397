import React, { useCallback, useContext } from 'react';
import { Button } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

import { ApiContentDirectory, ApiContentLoader } from '../component/api';
import { VerticalPadder, MaxWidth, PageWidthBoundary } from '../component/styled';
import { ListItem, ItemDetails } from '../component/api/article';
import { ScrollTopOnMount, Link } from '../component';
import { scrollToTop } from '../util';
import { subscribeTo, AppStateContainer } from '../state';


const UrlContext = React.createContext();

function BlogNews({ match, app, history, location }) {
	const canonicalizePageUrls = useCallback(page => {
		const url = app.state.auOnly ? '/blog' : '/blogs';

		if (page === 1) {
			return url;
		}

		return `${url}/page/${page}`;
	}, [ app.state.auOnly ]);

	if (!app.state.auOnly && (location.pathname === '/blog' || location.pathname.indexOf('/blog/') === 0)) {
		return <Redirect to="/blogs" />;
	}

	let { article: articleUrl, page } = match.params;
	let showingList = true
	const articleUrlParsed = parseInt(articleUrl, 10);

	if (articleUrl && articleUrl !== 'page' && (isNaN(articleUrlParsed) || articleUrlParsed.toString() !== articleUrl)) {
		showingList = false;
	}

	if (page) {
		page = parseInt(page);

		if (isNaN(page)) {
			page = undefined;
		}
	}

	const blogUrlBase = app.state.auOnly ? 'blog' : 'blogs';

	return (
		<>
			{showingList &&
				<VerticalPadder top={0}>
					<ApiContentLoader
						type={app.state.auOnly ? 'blog-hero' : 'blog-hero-v1'}
					/>
				</VerticalPadder>
			}
			<PageWidthBoundary>
				<ScrollTopOnMount />
				<VerticalPadder>
					<MaxWidth>
						<UrlContext.Provider value={blogUrlBase}>
							<ApiContentLoader
								type={app.state.auOnly ? 'blog_category_news' : 'au-blog-new'}
								component={ApiContentDirectory}
								canonical={blogUrlBase}
								canonicalPagination={canonicalizePageUrls}
								setMeta
								activeItemUrl={articleUrl}
								listItemComponent={ListArticle}
								detailsItemComponent={Article}
								onPageChange={newPage => {
									scrollToTop();

									history.push(canonicalizePageUrls(newPage));
								}}
								activePage={page}
							/>
						</UrlContext.Provider>
					</MaxWidth>
				</VerticalPadder>
			</PageWidthBoundary>
		</>
	);
}


const Article = React.memo(({ item }) => {
	const blogUrlBase = useContext(UrlContext);

	return (
		<ItemDetails
			item={item}
			backUrl={`/${blogUrlBase}`}
			sectionTitle="Blog &amp; News"
			imageRatioPercentage={44.75}
		/>
	);
});


const ListArticle = React.memo(({ item }) => {
	const blogUrlBase = useContext(UrlContext);
	const detailsUrl = `/${blogUrlBase}/${item.slug}`;

	return (
		<ListItem
			item={item}
			detailsUrl={detailsUrl}
			imageRatioPercentage={44.75}
			actions={(
				<Button
					variant="contained"
					component={Link}
					to={detailsUrl}
					color="primary"
				>
					Continue reading
				</Button>
			)}
		/>
	);
});


export default subscribeTo(
	{
		app: AppStateContainer
	},
	BlogNews
);
