import React, { useReducer, useRef, useCallback, useEffect } from 'react';
import {
	Grid, Typography, Paper, Button, TextField
} from '@material-ui/core';

import { apiFetch } from '../../util';
import { VerticalPadder } from '../../component/styled';


const SET_ERROR = 'SET_ERROR';
const SUBMITTING_START = 'SUBMITTING_START';
const SUBMITTING_END = 'SUBMITTING_END';
const SET_COMPLETE = 'SET_COMPLETE';

function reducer(state, action) {
	switch (action.type) {
		case SET_ERROR:
			return { error: action.payload, submitting: false };

		case SUBMITTING_START:
			return { submitting: true, error: undefined };

		case SUBMITTING_END:
			return { submitting: false };

		case SET_COMPLETE:
			return { completeHtml: action.payload };

		default:
			return state;
	}
}

const unknownErrorMsg = 'There was a problem while activating the bonus. Please try again.';

const initialState = {
	error: undefined,
	submitting: false,
	completeHtml: ''
};

function Promotions() {
	const [ state, dispatch ] = useReducer(reducer, initialState);

	const inputEl = useRef(null);

	const onSubmit = useCallback(
		() => {
			if (state.submitting) {
				return;
			}

			const { value: promotionCode } = inputEl.current;

			if (!promotionCode) {
				dispatch({ type: SET_ERROR, payload: 'Please enter a promo code' });
				return;
			}

			dispatch({ type: SUBMITTING_START });

			apiFetch.post(
				'player/bonus/activate',
				{
					data: { promotionCode, fromCashier: false }
				}
			)
				.then(response => {
					if (!mountRef.current) {
						return;
					}

					const { data: responseData } = response;

					if (!responseData.info.success || !responseData.data.message) {
						let error = unknownErrorMsg;

						if (responseData.data.message) {
							error = responseData.data.message;
						}

						dispatch({ type: SET_ERROR, payload: error });
						return;
					}

					dispatch({ type: SUBMITTING_END });
					dispatch({ type: SET_COMPLETE, payload: responseData.data.message });
				})
				.catch(() => {
					if (!mountRef.current) {
						return;
					}

					dispatch({ type: SET_ERROR, payload: unknownErrorMsg });
				});
		},
		[]
	);

	const onInputKey = useCallback(
		(ev) => {
			if (ev.key === 'Enter') {
				onSubmit();
			}
		},
		[]
	);

	const mountRef = useRef(false);
	useEffect(
		() => {
			mountRef.current = true;

			return () => {
				mountRef.current = false;
			};
		},
		[]
	);

	return (
		<Paper>
			<VerticalPadder>
				{state.completeHtml && <Typography align="center" component="div" dangerouslySetInnerHTML={{ __html: state.completeHtml }} />}
				{!state.completeHtml &&
					<Grid container justify="center" alignItems="center" spacing={8}>
						<Grid item xs={12}>
							<Typography align="center" component="p">If you have a promo code please enter it then click on activate.</Typography>
						</Grid>
						<Grid item>
							<TextField
								label="Promotional code"
								inputProps={{
									ref: inputEl,
									onKeyPress: onInputKey
								}}
								helperText={state.error || ''}
								error={!!state.error}
								FormHelperTextProps={{
									component: 'div'
								}}
								disabled={state.submitting}
								variant="filled"
							/>
						</Grid>
						<Grid item>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={state.submitting}
								onClick={onSubmit}
							>
								{state.submitting ? 'Activating promotion...' : 'Activate promotion'}
							</Button>
						</Grid>
					</Grid>
				}
			</VerticalPadder>
		</Paper>
	);
}


export default Promotions;