import React from 'react';
import Helmet from 'react-helmet-async';

import { NotFound as NotFoundComponent, ScrollTopOnMount } from '../component';
import { MaxWidth, VerticalPadder, PageWidthBoundary } from '../component/styled';
import { siteTitle } from '../util';


function NotFound() {
	return (
		<PageWidthBoundary>
			<ScrollTopOnMount />
			<Helmet>
				<title>{siteTitle('404 - Content not found')}</title>
			</Helmet>
			<VerticalPadder>
				<MaxWidth>
					<NotFoundComponent />
				</MaxWidth>
			</VerticalPadder>
		</PageWidthBoundary>
	);
}

export default NotFound;
