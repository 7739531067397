import * as Cookies from 'js-cookie';

import { inBrowser } from './';


export function initTawkWidget() {
	if (!inBrowser) {
		return;
	}

	let key = '5e8888ba69e9320caac034d5';

	if (Cookies.get('vipPlayer')) {
		key = '5bb4aa048a438d2b0ce00ac5';
	}

	const { Tawk_API } = window;

	window.Tawk_API = Tawk_API || {};
	window.Tawk_LoadStart = new Date();

	var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
	s1.async=true;
	s1.src=`https://embed.tawk.to/${key}/default`;
	s1.charset='UTF-8';
	s1.setAttribute('crossorigin','*');
	s0.parentNode.insertBefore(s1,s0);
}

export function hideTawkWidget() {
	if (!inBrowser) {
		return;
	}

	const { Tawk_API } = window;

	if (Tawk_API) {
		Tawk_API.hideWidget();
	}
}

export function showTawkWidget() {
	if (!inBrowser) {
		return;
	}

	const { Tawk_API } = window;

	if (Tawk_API) {
		Tawk_API.showWidget();
	}
}

export function showTawkChat() {
	if (!inBrowser) {
		return;
	}

	showTawkWidget();

	const { Tawk_API } = window;

	if (Tawk_API) {
		Tawk_API.maximize();
	}
}