import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ApiContentLoader, ApiSeoContent } from '../component/api';
import { VerticalPadder, PageWidthBoundary } from '../component/styled';

const BitcoinCasino = () => (
	<Switch>
		<Route key="best-casino-australia" path="/online-slots/best-casino-australia">
			<>
				<VerticalPadder top={0}>
					<ApiContentLoader
						type="best-casino-au-hero"
					/>
				</VerticalPadder>
				<PageWidthBoundary>
					<VerticalPadder>
						<ApiContentLoader
							type="best-casino-au-seo"
							setMeta
							canonicalUrl="online-slots/best-casino-australia"
						/>
					</VerticalPadder>
				</PageWidthBoundary>
			</>
		</Route>
		<Route key="online-slots" path="/online-slots">
			<>
				<VerticalPadder top={0}>
					<ApiContentLoader
						type="online-slots-hero-new"
					/>
				</VerticalPadder>
				<PageWidthBoundary>
					<VerticalPadder>
						<ApiContentLoader
							type="online-slots-seo-new"
							setMeta
							component={ApiSeoContent}
							canonicalUrl="online-slots"
						/>
					</VerticalPadder>
				</PageWidthBoundary>
			</>
		</Route>
	</Switch>
);

export default BitcoinCasino;
