import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Typography, Divider, Paper } from '@material-ui/core';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { ApiContentLoader, ApiSeoContent } from '../component/api';
import { VerticalPadder, PageWidthBoundary } from '../component/styled';

const BitcoinCasino = () => (
	<Switch>
		<Route key="best-casino-australia" path="/online-pokies/best-casino-australia">
			<>
				<VerticalPadder top={0}>
					<ApiContentLoader
						type="best-casino-au-hero"
					/>
				</VerticalPadder>
				<PageWidthBoundary>
					<VerticalPadder>
						<Submenu />
						<ApiContentLoader
							type="best-casino-au-seo"
							setMeta
							canonicalUrl="online-pokies/best-casino-australia"
						/>
						<ClearFix />
					</VerticalPadder>
				</PageWidthBoundary>
			</>
		</Route>
		<Route key="online-pokies" path="/online-pokies">
			<>
				<VerticalPadder top={0}>
					<ApiContentLoader
						type="onlinepokies-hero-v2"
					/>
				</VerticalPadder>
				<PageWidthBoundary>
					<VerticalPadder>
						<Submenu />
						<ApiContentLoader
							type="online-pokies-seo-v2"
							setMeta
							component={ApiSeoContent}
							canonicalUrl="online-pokies"
						/>
						<ClearFix />
					</VerticalPadder>
				</PageWidthBoundary>
			</>
		</Route>
	</Switch>
);

const Submenu = () => {
	return (
		<FloatedBox>
			<Typography
				component={Link}
				to="/online-pokies"
				variant="h6"
			>
				Online Pokies
			</Typography>
			<Divider />
			<VerticalPadder top={2} bottom={0}>
				<Typography
					component={Link}
					to="/online-pokies/best-casino-australia"
					variant="subtitle1"
				>
					Best Casino Australia
				</Typography>
			</VerticalPadder>
		</FloatedBox>
	);
};

const ClearFix = () => {
	return (
		<div style={{ clear: 'both' }}></div>
	);
};

const FloatedBox = styled(Paper)`
	float: right;
	padding: ${p => p.theme.spacing(3)}px;
	min-width: ${p => p.theme.spacing(40)}px;
	margin: ${p => p.theme.spacing(5)}px ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(2)}px;
`;

export default BitcoinCasino;
