import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import {
	AppStateContainer, subscribeTo
} from '../state';


function NavLink({ app, to, _renderIndex, ...rest }) {
	//to = app.url(to);

	return (
		<RouterNavLink to={to} {...rest} />
	);
}

export default subscribeTo(
	{
		app: AppStateContainer
	},
	NavLink
);
