import React from 'react';
import styled from 'styled-components/macro';
import { Typography, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Helmet from 'react-helmet-async';

import { ApiContent } from '..';
import { DetailsImageContainer, Actions, ClearFix } from '.';
import { MaxWidth } from '../../styled';
import { Link } from '../..';
import { siteTitle } from '../../../util';


const ItemDetails = React.memo(({ backUrl, sectionTitle, item, actions, imageRatioPercentage }) => {
	let content = null;

	if (!item) {
		content = <Typography color="error" align="center" variant="subtitle1" component="p">Specified article doesn't exist.</Typography>;
	} else {
		content = (
			<>
				<Helmet>
					<title>{siteTitle(item.meta.title)}</title>
					<meta name="description" content={item.meta.description} />
				</Helmet>
				{
					item.image.src &&
					<MaxWidth factor={86}>
						<DetailsImageContainer ratioPercentage={imageRatioPercentage}>
							<img src={process.env.REACT_APP_API_CMS_IMAGE_ROOT + item.image.src} alt={item.image.alt} />
						</DetailsImageContainer>
					</MaxWidth>
				}
				{
					item.title &&
					<Typography
						variant="h4"
						component="h1"
						color="primary"
						gutterBottom
					>
						{item.title}
					</Typography>
				}
				<ApiContent>{item.content}</ApiContent>
				{
					actions &&
					<Actions component="div" align="center">
						{actions}
					</Actions>
				}
				<ClearFix />
			</>
		);
	}

	return (
		<>
			{content}
			<Footer>
				<IconButton component={Link} to={backUrl} color="primary">
					<ArrowBackIcon />
				</IconButton>
				<FooterAction variant="subtitle1" component="p">Back</FooterAction>
			</Footer>
		</>
	);
});

const Footer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${p => p.theme.spacing(.5)}px;
`;

const FooterAction = styled(Typography)`
	margin-left: ${p => p.theme.spacing(2)}px;
`;


export default ItemDetails;