import React, { useEffect } from 'react';


function XcmSeal() {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://05c2e060-dcf7-4038-bd3f-e1de90671e8c.seals-xcm.certria.com/xcm-seal.js';

    document.getElementsByTagName('head')[0].appendChild(script);

    return () => {
      script.remove();
    };
  }, []);

  return (
    <div
      id="xcm-05c2e060-dcf7-4038-bd3f-e1de90671e8c"
      data-xcm-seal-id="05c2e060-dcf7-4038-bd3f-e1de90671e8c"
      data-xcm-image-size="128"
      data-xcm-image-type="basic-small"
    />
  );
}

export default XcmSeal;