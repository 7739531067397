import React from 'react';
import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components/macro';
import Helmet from 'react-helmet-async';

import { ApiContentLoader, ApiSeoContent, OpenGameFromList, ApiCarousel } from '../component/api';
import { providerDefinitions } from '../data';
import { VerticalPadder, PageWidthBoundary } from '../component/styled';
import { ResponsiveImageContainer, ScrollTopOnMount, Link } from '../component';
import { SecondaryCarousel } from '../component/api/Carousels';
import { subscribeTo, AppStateContainer } from '../state';


// 180 / 300
const ProviderImageRatio = styled(ResponsiveImageContainer)`
	padding-bottom: 60%;
`;

function filterLiveCasinoProviders(providerId) {
	const { sections } = providerDefinitions[providerId];

	return Array.isArray(sections) && sections.indexOf('liveCasino') !== -1;
}

const liveCasinoProviders = Object.keys(providerDefinitions).filter(filterLiveCasinoProviders);


class LiveCasino extends React.Component {

	render() {
		const { match, app } = this.props;
		let content = null;
		const { providerId } = match.params;

		let canonicalUrl = 'livecasino';

		if (providerId) {
			content = (
				<Provider
					providerId={providerId}
				/>
			);
			canonicalUrl += '/' + providerId;
		} else {
			content = <List />;
		}

		return (
			<>
				<VerticalPadder top={0} bottom={0}>
					<ApiContentLoader
						type={app.state.auOnly ? 'livecasino-hero-v2' : 'livecasino-hero-new'}
						component={ApiCarousel}
					/>
				</VerticalPadder>
				<PageWidthBoundary>
					<ScrollTopOnMount />
					<VerticalPadder>
						<ApiContentLoader
							type="livecasino-content_slider"
							component={SecondaryCarousel}
						/>
					</VerticalPadder>
					<VerticalPadder>
						{content}
					</VerticalPadder>
					<VerticalPadder>
						<ApiContentLoader
							type={app.state.auOnly ? 'livecasino-seo-v2' : 'live-casino-seo-new'}
							component={ApiSeoContent}
							setMeta
						/>
					</VerticalPadder>
					<Helmet>
						<link rel="canonical" href={app.canonicalize(canonicalUrl)} />
					</Helmet>
				</PageWidthBoundary>
			</>
		);
	}

}

class Provider extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			provider: undefined,
			providerId: undefined
		};
	}

	static getDerivedStateFromProps(props, state) {
		let newState = null;
		const { providerId } = props;

		if (state.provider === undefined || providerId !== state.providerId) {
			let provider = null;

			if (liveCasinoProviders.indexOf(providerId) !== -1) {
				provider = providerDefinitions[providerId];
			}

			newState = {
				provider,
				providerId
			};
		}

		return newState;
	}

	render() {
		const { provider, providerId } = this.state;

		if (provider === null) {
			return (
				<Typography variant="h5" component="p">Specified provider doesn't exist</Typography>
			);
		}

		return (
			<>
				<Grid container justify="flex-start" alignItems="center" spacing={24}>
					<Grid item>
						<IconButton component={Link} to="/livecasino">
							<ArrowBackIcon color="primary" />
						</IconButton>
					</Grid>
					<Grid item>
						<Typography variant="subtitle1" component="p">Live casino: {provider.name}</Typography>
					</Grid>
				</Grid>
				<VerticalPadder>
					<OpenGameFromList
						providers={providerId}
						categories="livecasino"
					/>
				</VerticalPadder>
			</>
		);
	}
}


class List extends React.PureComponent {

	render() {
		return (
			<>
				<Typography variant="h5" component="p" gutterBottom>Live Casino Providers</Typography>
				<Grid container justify="center" spacing={16}>
					{liveCasinoProviders.map((providerId) => {
						const provider = providerDefinitions[providerId];

						return (
							<StyledGrid item key={providerId} xs={12}>
								<ProviderImageWrapper>
									<ProviderImageRatio>
										<img src={provider.images.list} alt="" />
									</ProviderImageRatio>
									<ActionWrapper align="center">
										<Button
											color="primary"
											variant="contained"
											component={Link}
											to={`/livecasino/${providerId}`}
										>
											Play
										</Button>
									</ActionWrapper>
								</ProviderImageWrapper>
								<Typography align="center" variant="h5" component="p" noWrap>{provider.name}</Typography>
							</StyledGrid>
						);
					})}
				</Grid>
			</>
		);
	}

}

const StyledGrid = styled(Grid)`
	flex-grow: 1;
	max-width: 300px;
	min-width: 100px;
`;

const ProviderImageWrapper = styled.div`
	width: 100%;
	position: relative;
	font-size: 0;
`;

const ActionWrapper = styled(Typography)`
	position: absolute;
	top: auto;
	bottom: ${p => p.theme.spacing(2)}px;
	width: 100%;
`;

export default subscribeTo(
	{
		app: AppStateContainer
	},
	LiveCasino
);
