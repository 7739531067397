import { BaseContainer } from '.';
import { hideTawkWidget, showTawkWidget, apiFetch } from '../util';
import { defaultCasinoProviderKeys } from '../data';


const slashesRegExp = new RegExp('^/+');

class AppStateContainer extends BaseContainer {

	static initialState() {
		return {
			isMobile: undefined,
			ipCountry: 'US',
			showMobileMenu: false,
			showLogin: false,
			gameId: null,
			showSuccessfulLoginMessage: false,
			casinoGameProviders: defaultCasinoProviderKeys,
			aAid: null,
			aBid: null,
			auOnly: false
		};
	}

	constructor(isMobile, aAid, aBid, ipCountry, requestAuNz) {
		super();

		this._isMobile = isMobile;
		this._ipCountry = ipCountry;
		this._requestAuNz = requestAuNz;
		this.state.isMobile = isMobile;
		this.state.ipCountry = ipCountry;
		this.state.aAid = aAid;
		this.state.aBid = aBid;
		this.state.auOnly = requestAuNz;
	}

	reset = (override = {}) => {
		super.reset({
			isMobile: this._isMobile,
			ipCountry: this._ipCountry,
			auOnly: this._requestAuNz,
			...override
		});
	};

	hideNavigation = () => {
		this.setState({ showMobileMenu: false });
		showTawkWidget();
	};

	showNavigation = () => {
		this.setState({ showMobileMenu: true });
		hideTawkWidget();
	};

	hideLogin = () => {
		this.setState({ showLogin: false });
		showTawkWidget();
	};

	showLogin = () => {
		this.setState({ showLogin: true });
		hideTawkWidget();
	};

	setGameId = gameId => {
		this.setState({ gameId });
		hideTawkWidget();
	};

	unsetGameId = () => {
		this.setState({ gameId: null });
		showTawkWidget();
	};

	flashLoginMessage = () => {
		this.setState({ showSuccessfulLoginMessage: true }, () => setTimeout(this.hideLoginMessage, 5000));
	};

	hideLoginMessage = () => {
		this.setState({ showSuccessfulLoginMessage: false });
	};

	updateGameProviders = () => {
		apiFetch('games/providers/video-slots,table-games,video-poker,scratch-cards,virtual-games/true')
			.then(response => {
				let casinoGameProviders = defaultCasinoProviderKeys;

				if (response && response.data && response.data.data && Array.isArray(response.data.data.providers)) {
					casinoGameProviders = response.data.data.providers.filter(p => p !== 'all');
				}

				this.setState({ casinoGameProviders });
			})
			.catch(() => {
				this.setState({ casinoGameProviders: defaultCasinoProviderKeys });
			});
	};

	url = url => url;

	canonicalize = (url = '/') => {
		if (url && typeof url === 'string' && url[0] !== '/') {
			url = '/' + url;
		}

		url = this.url(`${url || ''}`).replace(slashesRegExp, '');

		return process.env.REACT_APP_BASE_URL + url;
	};

}

export default AppStateContainer;