
export const defaultMuiThemeColors = {
	palette: {
		action: {
			active: '#ffffff',
			hover: 'rgba(255, 255, 255, .1)',
			selected: 'rgba(255, 255, 255, .28)'
		},
		background: {
			default: '#ffffff',
			paper: '#eeeeee',
			notification: '#f8bf0e'
		},
		primary: {
			light: '#f3d573',
			main: '#f8bf0e',
			dark: '#bc8e04',
			contrastText: '#001c4c'
		},
		secondary: {
			light: '#132e5d',
			main: '#001c4c',
			dark: '#0a1b37',
			contrastText: '#f8bf0e'
		},
		text: {
			primary: '#555555',
			secondary: '#999999',
			disabled: '#aaaaaa'
		}
	},
	overrides: {
		MuiFormHelperText: {
			root: {
				marginBottom: '.3em',
				'& p': {
					margin: 0,
					marginBottom: '.3em'
				}
			}
		}
	}
};

export const muiThemeCommon = {
	palette: {
		type: 'light'
	},
	typography: {
		useNextVariants: true,
		fontSize: 14,
		htmlFontSize: 16,
	}
};