import React, { useMemo } from 'react';
import Helmet from 'react-helmet-async';
import { Grid } from '@material-ui/core';

import { ApiCarousel, ApiContentLoader, ApiSeoContent, ApiGamesLoader } from '../component/api';
import { VerticalPadder, PageWidthBoundary } from '../component/styled';
import { GameGrid, ScrollTopOnMount, PromotionsListArticle } from '../component';
import { sortByPosition } from '../util';
import { AppStateContainer, subscribeTo } from '../state';


const Home = ({ appState }) => {
	const { isMobile, casinoGameProviders, auOnly } = appState.state;

	const providerKeysString = casinoGameProviders.join(',');

	const randomSelection = useMemo(() => {
		return data => {
			const numOfItems = isMobile ? 12 : 10;

			if (data.length <= numOfItems) {
				return data;
			}

			const selectedIndexes = [];
			const output = [];
			let control = 0;

			while (selectedIndexes.length < numOfItems) {
				if (control > 200) {
					break;
				}

				control++;
				const newIndex = Math.floor(Math.random() * data.length);

				if (selectedIndexes.indexOf(newIndex) !== -1) {
					continue;
				}

				selectedIndexes.push(newIndex);
				output.push(data[newIndex]);
			}

			return output;
		};
	}, [ isMobile ]);

	return (
		<>
			<ApiContentLoader
				type={auOnly ? 'home-hero-v2' : 'home-slider-v2'}
				component={ApiCarousel}
				bgColor="#1f3c74"
			/>
			<PageWidthBoundary>
				<Helmet>
					<link key="canonical" rel="canonical" href={appState.canonicalize()} />
				</Helmet>
				<ScrollTopOnMount />
				<VerticalPadder>
					<ApiGamesLoader
						endpoint="/games/mostPlayed/all/50"
						filter={randomSelection}
					>
						{(games, loading) => <GameGrid games={games} loading={loading} />}
					</ApiGamesLoader>
				</VerticalPadder>
				<VerticalPadder>
					<ApiGamesLoader
						endpoint={`games/filter/new/${providerKeysString}/nosort/100/0`}
						filter={randomSelection}
					>
						{(games, loading) => <GameGrid games={games} loading={loading} />}
					</ApiGamesLoader>
				</VerticalPadder>
				<VerticalPadder>
					<ApiContentLoader
						type={auOnly ? 'home-seo-v2' : 'au-home-seo'}
						component={ApiSeoContent}
						setMeta
					/>
				</VerticalPadder>
				<VerticalPadder>
					<ApiContentLoader
						type={auOnly ? 'promotions' : 'promotions-v1'}
						component={PromotionsHome}
					/>
				</VerticalPadder>
				{
					auOnly &&
					<VerticalPadder>
						<ApiContentLoader
							type="home-seo-bottom"
							component={ApiSeoContent}
						/>
					</VerticalPadder>
				}
			</PageWidthBoundary>
		</>
	);
};


function validPromotions(promo) {
	return !promo.type;
}

const PromotionsHome = React.memo(({ content }) => {
	let promotions = content.children.filter(validPromotions);
	promotions = promotions.sort(sortByPosition).slice(0, 2);

	return (
		<Grid container spacing={16}>
			{promotions.map(promo => <PromotionsListArticle key={promo.title} item={promo} titleComponent="div" subtitleComponent="h3" />)}
		</Grid>
	);
});

export default subscribeTo(
	{
		appState: AppStateContainer
	},
	Home
);
