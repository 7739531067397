import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components/macro';

import { subscribeTo, SiteStatsContainer, PlayerContainer } from '../state';
import { PageWidthBoundary } from './styled';
import { diamond } from '../img';

import './StatsTicker.css'


function Tick({ label, value }) {
	return (
		<li>
			<TickerText color="primary" variant="body1">
				<img src={diamond} alt="" />
				{label}
				<span>{value}</span>
			</TickerText>
		</li>
	);
}

const TickerText = styled(Typography)`
	display: flex;
	align-items: center;

	span {
		display: inline-block;
		color: #fff;
		font-weight: bold;
		margin: 0 1em
	}

	img {
		display: inline-block;
		width: 30px;
		height: auto;
		margin: 0 1em 0 0
	}
`;

function generatePlayersList(players, formatCurrency) {
	let listString = '';

	if (!players) {
		return '';
	}

	for (const p of players) {
		listString += `${p.nickname.substr(0, 3)}...: ${formatCurrency(p.value)}, `;
	}

	return listString.substr(0, listString.length - 2);
}

function StatsTicker({ stats, player }) {
	useEffect(() => {
		stats.fetch()
	}, [ stats ]);

	const {
		initialized, playersCurentlyOnline, topWinYesterday,
		topWinThisWeek, topWinLastWeek
	} = stats.state;
	const { formatCurrency } = player;

	return (
		<WrapperBg>
			<CustomPWB>
				<div className="tickerWrap">
					{
						initialized &&
						<ul className="ticker">
							<Tick key="online" label="Currently active players:" value={playersCurentlyOnline} />
							<Tick key="topY" label="Top win yesterday:" value={generatePlayersList(topWinYesterday, formatCurrency)} />
							<Tick key="topTW" label="Top win this week:" value={generatePlayersList(topWinThisWeek, formatCurrency)} />
							<Tick key="topLW" label="Top win last week:" value={generatePlayersList(topWinLastWeek, formatCurrency)} />
						</ul>
					}
					{
						!initialized &&
						<ul className="ticker empty" />
					}
				</div>
			</CustomPWB>
		</WrapperBg>
	);
}

const WrapperBg = styled.div`
	width: 100%;
	background-color: #001c4c;
	min-height: 46px;
`;

const CustomPWB = styled(PageWidthBoundary)`
	overflow: hidden;
`;


export default subscribeTo(
	{
		stats: SiteStatsContainer,
		player: PlayerContainer
	},
	StatsTicker
);