import { useEffect } from 'react';

import { initTawkWidget } from '../util';


function TawkTo() {
	useEffect(() => {
		initTawkWidget();
	}, []);

	return null;
}

export default TawkTo;
