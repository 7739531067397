import React from 'react';
import { Button, Grid, Typography, Paper } from '@material-ui/core';
import styled from 'styled-components/macro';

import { ApiContent } from './api';
import { MaxWidth, VerticalPadder } from './styled';
import { PromotionsImageContainer, Link } from '.';


export default React.memo(({ item, titleComponent = 'h2', subtitleComponent = 'h3' }) => {
	if (item.type) {
		return null;
	}

	return (
		<ScalingGrid item sm={12} md={6}>
			<Paper>
				<PromotionsImageContainer>
					<img src={process.env.REACT_APP_API_CMS_IMAGE_ROOT + item.image.src} alt={item.image.alt} />
				</PromotionsImageContainer>
				<VerticalPadder left={2} right={2} bottom={1}>
					<ContentWrapper factor={80}>
						<Typography component={titleComponent} variant="h6" dangerouslySetInnerHTML={{ __html: item.title }} />
						<Typography component={subtitleComponent} variant="subtitle1" dangerouslySetInnerHTML={{ __html: item.summary }} />
						<ApiContent>{item.content}</ApiContent>
					</ContentWrapper>
				</VerticalPadder>
				<CenterText>
					<Button
						variant="contained"
						component={Link}
						to={`/content/promotionsb/${item.slug}`}
						color="secondary"
					>
						Read more
					</Button>
				</CenterText>
			</Paper>
		</ScalingGrid>
	);
});

const ScalingGrid = styled(Grid)`
	position: relative;
	transition: transform ease-in .1s;

	:hover {
		z-index: 1;
		transform: scale(1.04);
	}
`;

const CenterText = styled.div`
	text-align: center;
	padding: 0 0 ${p => p.theme.spacing(1)}px;
`;

const ContentWrapper = styled(MaxWidth)`
	height: 400px;
	position: relative;
	overflow: hidden;

	:after {
		content: '';
		width: 100%;
		height: 100px;
		position: absolute;
		top: auto;
		bottom: 0;
		left: 0;
		background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(238,238,238,1) 100%);
	}
`;