import React from 'react';
import styled from 'styled-components/macro';
import { Typography, Grid, Button } from '@material-ui/core';

import { support } from '../img';
import { ProviderCarousel, ApiContentLoader, PaymentCarousel } from './api';
import { footerItems } from '../data';
import { showTawkChat } from '../util';
import { VerticalPadder, MaxWidth } from './styled';
import { facebook, twitter, youtube } from '../img/icons/follow';
import { PostAffiliatePro, Link, ApgSeal, XcmSeal } from '.';
import { subscribeTo, AppStateContainer } from '../state';


function footerLink(link) {
	return (
		<li key={link.url}>
			<WhiteText style={{ display: 'inline-block' }} variant="caption" color="textPrimary" component={Link} to={link.url}>
				{link.label}
			</WhiteText>
		</li>
	);
}

const Footer = React.memo(({ appState }) => {
	const { ipCountry } = appState.state;
	const showLicense = ipCountry === 'GB' || ipCountry === 'NL' || ipCountry === 'CW';

	return (
		<MainWrapper>
			<SliderWrapper>
				<MaxWidth factor={80}>
					<VerticalPadder>
						<ApiContentLoader
							type="provider-slider"
							component={ProviderCarousel}
						/>
					</VerticalPadder>
					<VerticalPadder>
						<ApiContentLoader
							type="payment-slider"
							component={PaymentCarousel}
						/>
					</VerticalPadder>
				</MaxWidth>
			</SliderWrapper>
			<VerticalPadder top={0} bottom={0} left={1} right={1}>
				<MaxWidth>
					<VerticalPadder>
						<Grid container alignItems="center">
							<Grid item xs={12} sm={6}>
								<Typography component="p" variant="h6" color="primary" gutterBottom align="center">Customer support</Typography>
								<Typography align="center" gutterBottom><img src={support} alt="" style={{ height: '100px' }} /></Typography>
								<WhiteText component="p" variant="body1" gutterBottom align="center">Our friendly customer service team is on hand to answer any questions you may have 24 hours a day, 7 days a week.</WhiteText>
								<WhiteText variant="body1" component="p" align="center">Contact us today on:</WhiteText>
								<CsButtonWrapper>
									<div>
										<Button
											color="primary"
											variant="contained"
											onClick={showTawkChat}
										>
											Live chat support
										</Button>
									</div>
									<div>
										<Button color="primary" variant="outlined" component={Link} to="/contact">E-mail support</Button>
									</div>
								</CsButtonWrapper>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FooterLinks>
									{footerItems.map(footerLink)}
								</FooterLinks>
							</Grid>
						</Grid>
					</VerticalPadder>
					<Grid container justify="center" spacing={0}>
						<Grid item xs={12}>
							<WhiteText align="center" component="p" variant="subtitle1">Follow us</WhiteText>
						</Grid>
						<Grid item>
							<SocialLink
								component="a"
								href="https://www.facebook.com/AU-Slots-1289411397823089/"
								target="_blank"
								className="facebook"
							>
							</SocialLink>
						</Grid>
						<Grid item>
							<SocialLink
								component="a"
								href="https://twitter.com/auslots"
								target="_blank"
								className="twitter"
							>
							</SocialLink>
						</Grid>
						<Grid item>
							<SocialLink
								component="a"
								href="https://www.youtube.com/channel/UCNAEbBA90LfUC6WcfBx3deQ"
								target="_blank"
								className="youtube"
							>
							</SocialLink>
						</Grid>
					</Grid>
					<PostAffiliatePro />
				</MaxWidth>
			</VerticalPadder>
			<StyledFooter>
				<MaxWidth factor={150}>
					<Grid container spacing={16} alignItems="center" justify="center" direction="column">
						{
							showLicense &&
							<>
								<Grid item md={6}>
									<ApgSeal />
								</Grid>
								<Grid item md={6}>
									<XcmSeal />
								</Grid>
								<Grid item md={6}>
									<WhiteText paragraph>Versus Odds B.V. bearing company registration number 147011, a company incorporated under the laws of Curaçao, having its registered address at Korporaalweg 10, Willemstad, Curaçao, holder of the License 8048/JAZ2021-033, issued by Antillephone N.V. Payments are processed by Deloraze Limited, with its registered address at Themistoklis Dervis Street 41, Hawaii Tower, 1st Floor, 1066 Nicosia, Cyprus, registration number 410283, as per agreement between the two companies.</WhiteText>
								</Grid>
							</>
						}
						{
							!showLicense &&
							<>
								<Grid item md={6}>
									<ApgSeal />
								</Grid>
								<Grid item md={6}>
									<XcmSeal />
								</Grid>
								<Grid item md={6}>
									<WhiteText align="center" variant="caption">Auslots is operated, licensed and authorised to offer online gaming services and makes no representation as to the legality of such services in other jurisdictions.</WhiteText>
								</Grid>
							</>
						}
						<Grid item md={6}>
							<WhiteText align="center" variant="caption">Only players above the age of 18 and who reside in countries where gambling is legal are allowed to play on {process.env.REACT_APP_USER_FRIENDLY_URL}</WhiteText>
						</Grid>
					</Grid>
				</MaxWidth>
				<WhiteText
					align="center"
					variant="caption"
				>
					© 2023 {process.env.REACT_APP_SITE_NAME}. All rights reserved.
				</WhiteText>
			</StyledFooter>
		</MainWrapper>
	);
});

const MainWrapper = styled.footer`
	background: ${p => p.theme.mui.palette.secondary.main};
`;

const SliderWrapper = styled.div`
	background: ${p => p.theme.mui.palette.background.paper};
`;

const FooterLinks = styled.ul`
	text-align: right;
	list-style: none;

	> li {
		margin: ${p => p.theme.spacing(.5)}px;
	}
`;

const CsButtonWrapper = styled.div`
	display: flex;
	padding: ${p => p.theme.spacing()}px 0 ${p => p.theme.spacing(2)}px;
	justify-content: space-evenly;
	align-items: center;
`;

const StyledFooter = styled.div`
	background-color: ${p => p.theme.mui.palette.primary.main};
	padding: ${p => p.theme.spacing()}px;
`;

const SocialLink = styled(Typography)`
	border-radius: 50%;
	border: ${p => p.theme.spacing(.25)}px solid ${p => p.theme.mui.palette.primary.main};
	margin: ${p => p.theme.spacing(2)}px;
	position: relative;
	display: inline-block;
	width: ${p => p.theme.spacing(6)}px;
	height: ${p => p.theme.spacing(6)}px;

	:before {
		position: absolute;
		top: 0;
		left: 10;
		width: 100%;
		height: 100%;
		content: '';
		background: no-repeat center;
		background-size: 70%;
	}

	&.youtube:before {
		background-image: url(${youtube});
	}

	&.facebook:before {
		background-image: url(${facebook});
	}

	&.twitter:before {
		background-image: url(${twitter});
	}
`;

const WhiteText = styled(Typography)`
	color: #fff;
`;

export default subscribeTo(
	{
		appState: AppStateContainer
	},
	Footer
);
