import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Helmet from 'react-helmet-async';
import usePagination from 'use-pagination'

import { ApiContent } from '.';
import { LoadingIndicator, ScrollTopOnMount, NotFound, Pagination } from '..';
import { VerticalPadder } from '../styled';
import { siteTitle } from '../../util';
import { subscribeTo, AppStateContainer } from '../../state';


function ApiContentDirectory(props) {
	const { content } = props;

	if (!content) {
		return (
			<LoadingIndicator />
		);
	}

	const {
		canonical: canonicalUrl, listWrapper, canonicalPagination: canonicalPaginationUrl,
		activeItemUrl, listItemComponent, detailsItemComponent: DetailsComponent,
		onPageChange, listWrapperProps, itemsPerPage, filter, app
	} = props;

	let { activePage } = props;

	let showDetails = false;

	if (activeItemUrl && activeItemUrl !== 'page') {
		activePage = parseInt(activeItemUrl, 10);

		if (isNaN(activePage) || activePage.toString() !== activeItemUrl) {
			activePage = 1;
			showDetails = true;
		}
	}

	const children = typeof filter === 'function' ? content.children.filter(filter) : content.children;

	const pagination = usePagination({
		items: children,
		itemsPerPage,
		initialPage: activePage
	});

	if (showDetails) {
		const item = children.find(c => c.slug === activeItemUrl);

		if (!item) {
			return (
				<>
					<Helmet key="notFoundHelmet">
						<title>{siteTitle('404 - Content not found')}</title>
					</Helmet>
					<ScrollTopOnMount key="notFound" />
					<NotFound />
				</>
			);
		}

		return (
			<>
				{
					canonicalUrl &&
					<Helmet key="detailsHelmet">
						<link rel="canonical" href={app.canonicalize(`${canonicalUrl}/${item.slug}`)} />
					</Helmet>
				}
				<ScrollTopOnMount key="details" />
				<DetailsComponent
					item={item}
				/>
			</>
		);
	} else {
		let canonicalPageUrl = canonicalUrl;

		if (typeof canonicalPaginationUrl === 'function') {
			canonicalPageUrl = canonicalPaginationUrl(pagination.currentPage);
		}

		return (
			<>
				{
					canonicalPageUrl &&
					<Helmet key="listHelmet">
						<link rel="canonical" href={app.canonicalize(canonicalPageUrl)} />
					</Helmet>
				}
				<ScrollTopOnMount key="list" />
				<List
					content={content}
					items={pagination.currentItems}
					itemComponent={listItemComponent}
					listWrapper={listWrapper}
					listWrapperProps={listWrapperProps}
				/>
				{
					onPageChange &&
					<Pagination
						canonical={canonicalPaginationUrl || canonicalUrl}
						pagination={pagination}
						onPageChange={page => {onPageChange(page); pagination.setCurrentPage(page)}}
					/>
				}
			</>
		);
	}

}

ApiContentDirectory.propTypes = {
	content: PropTypes.object,
	hideTitle: PropTypes.bool,
	activeItemUrl: PropTypes.string,
	canonical: PropTypes.string,
	listItemComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
	detailsItemComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
	listWrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
	listWrapperProps: PropTypes.object,
	itemsPerPage: PropTypes.number,
	filter: PropTypes.func,
	activePage: PropTypes.number,
	canonicalPagination: PropTypes.func,
};

ApiContentDirectory.defaultProps = {
	hideTitle: false,
	listItemComponent: ListItem,
	detailsItemComponent: Item,
	listWrapper: null,
	listWrapperProps: {},
	itemsPerPage: 5,
	activePage: 1,
	filter: null,
	canonicalPagination: undefined
};


function List({
	content: contentProp,
	itemComponent: ItemComponent,
	items,
	listWrapper: ListWrapper,
	listWrapperProps
}) {
	let listContent = null;

	if (!Array.isArray(items) || items.length === 0) {
		listContent = <Typography color="error" align="center" variant="h6">No content yet...</Typography>;
	} else {
		listContent = items.map(item => <ItemComponent key={item.id} item={item} />);
	}

	return (
		<>
			{contentProp.title &&
				<Typography variant="h5" component="p" gutterBottom>
					{contentProp.title}
				</Typography>
			}
			{contentProp.summary && <ApiContent>{contentProp.summary}</ApiContent>}
			{
				ListWrapper &&
				<ListWrapper {...listWrapperProps}>
					{listContent}
				</ListWrapper>
			}
			{!ListWrapper && listContent}
		</>
	);
}

function ListItem(props) {
	const { item } = props;

	return (
		<VerticalPadder>
			<Typography
				variant="h6"
				gutterBottom
			>
				{item.title}
			</Typography>
			{item.summary && <ApiContent>{item.summary}</ApiContent>}
		</VerticalPadder>
	);
}


function Item(props) {
	const { item } = props;
	let content = null;

	if (!item) {
		content = <Typography color="error">Specified item doesn't exist.</Typography>;
	} else {
		content = <ApiContent>{item.content}</ApiContent>;
	}

	return (
		<>
			{item.title && <Typography variant="h5" component="p" gutterBottom>{item.title}</Typography>}
			{item.summary && <ApiContent>{item.summary}</ApiContent>}
			{content}
		</>
	);
}


export default subscribeTo(
	{
		app: AppStateContainer
	},
	ApiContentDirectory
);