import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import Helmet from 'react-helmet-async';

import { ApiContentDirectory, ApiContentLoader, ApiContent } from '../component/api';
import { VerticalPadder, MaxWidth, PageWidthBoundary } from '../component/styled';
import { ScrollTopOnMount, PromotionsImageContainer, PromotionsListArticle, Link } from '../component';
import { sortByPosition, siteTitle } from '../util';
import { subscribeTo, AppStateContainer } from '../state';


function Promotions({ app, match }) {
	const { promotion: articleUrl } = match.params;

	return (
		<>
			{!articleUrl &&
				<VerticalPadder top={0} bottom={0}>
					<ApiContentLoader
						type={app.state.auOnly ? 'promotions-hero-v2' : 'promo-hero-v1'}
					/>
				</VerticalPadder>
			}
			<PageWidthBoundary>
				<ScrollTopOnMount />
				<VerticalPadder bottom={4}>
					<MaxWidth>
						<ApiContentLoader
							type={app.state.auOnly ? 'promotions' : 'promotions-v1'}
							component={ApiContentDirectory}
							activeItemUrl={articleUrl}
							listItemComponent={PromotionsListArticle}
							detailsItemComponent={Article}
							canonical="content/promotionsb"
							listWrapper={Grid}
							listWrapperProps={{
								container: true,
								spacing: 16
							}}
							sortBy={sortByPosition}
							itemsPerPage={20}
							setMeta
						/>
					</MaxWidth>
				</VerticalPadder>
			</PageWidthBoundary>
		</>
	);
}


const Article = React.memo(({ item }) => {
	return (
		<>
			<Helmet>
				<title>{siteTitle(item.meta.title)}</title>
				<meta name="description" content={item.meta.description} />
			</Helmet>
			<Typography component="h2" variant="h6" dangerouslySetInnerHTML={{ __html: item.title }} />
			<Typography component="h3" variant="subtitle1" dangerouslySetInnerHTML={{ __html: item.summary }} />
			<VerticalPadder>
				<PromotionsImageContainer>
					<img src={process.env.REACT_APP_API_CMS_IMAGE_ROOT + item.image.src} alt={item.image.alt} />
				</PromotionsImageContainer>
			</VerticalPadder>
			<VerticalPadder left={2} right={2} bottom={1}>
				<ApiContent>{item.content}</ApiContent>
			</VerticalPadder>
			<Typography component="div" align="center" gutterBottom>
				<Button
					variant="contained"
					component={Link}
					to={`/content/promotionsb`}
					color="secondary"
				>
					Back
				</Button>
			</Typography>
		</>
	);
});


export default subscribeTo(
	{
		app: AppStateContainer
	},
	Promotions
);