import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Fab, Dialog, DialogContent, Typography, Button, Grid, IconButton } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';

import { subscribeTo, PlayerContainer, PaymentWithdrawalsContainer } from '../state';
import { apiFetch } from '../util';
import { LoadingIndicator } from '.';


const FixedPositionWrapper = styled.div`
	position: fixed;
	top: auto;
	left: ${p => p.theme.spacing(2)}px;
	bottom: ${p => p.theme.spacing(2)}px;
	right: auto;

	${p => p.theme.mui.breakpoints.down('sm')} {
		left: ${p => p.theme.spacing()}px;
		bottom: ${p => p.theme.spacing()}px;
	}

	${p => p.theme.mui.breakpoints.down('xs')} {
		bottom: ${p => p.theme.spacing(6)}px;
	}
`;

const TitleWrapper = styled.div`
	position: relative;
	margin: 0;
	padding: ${p => p.theme.spacing(3)}px;
	padding-bottom: ${p => p.theme.spacing(2.5)}px;
`;

const CloseButton = styled(IconButton)`
	position: absolute;
	top: ${p => p.theme.spacing(.5)}px;
	bottom: auto;
	right: ${p => p.theme.spacing(.5)}px;
	left: auto;
`;

function DialogCloseTitle({ children, onClose }) {
	return (
		<TitleWrapper>
			<Typography variant="h6">{children}</Typography>
			{
				onClose &&
				<CloseButton aria-label="Close" onClick={onClose} color="primary">
					<CloseIcon />
				</CloseButton>
			}
		</TitleWrapper>
	);
}


function PendingWithdrawalsWidget({ player, wds }) {
	const [ dialogOpen, setDialogOpen ] = useState(false);
	const [ cancelResponse, setCancelResponse ] = useState(undefined);

	const handleClose = useCallback(() => {
		setDialogOpen(false);
	}, []);

	const handleCancelResponse = useCallback(response => {
		const { data, info } = response.data;

		if (info.success && data.messages) {
			setCancelResponse(data.messages);
			wds.fetch(true);
		}
	}, []);

	const cancelAll = useCallback(() => {
		setCancelResponse(null);

		apiFetch.post(
			'player/transactions/pendingWithdrawals/cancel',
			{
				data: {
					trans_ids: wds.state.transactions
				}
			}
		)
			.then(handleCancelResponse)
			.catch(error => handleCancelResponse(error.response));
	}, []);

	const checkWds = useCallback(() => {
		if (!player.loggedIn()) {
			return;
		}

		wds.fetchIfOld();
	}, [ player.loggedIn() ]);

	useEffect(() => {
		let timeoutRef = setInterval(checkWds, 1000);

		return () => {
			clearInterval(timeoutRef);
		};
	}, [ checkWds ]);

	useEffect(() => {
		checkWds();
	}, []);

	return (
		<>
			<Dialog
				open={dialogOpen}
				onClose={handleClose}
			>
				<DialogCloseTitle onClose={cancelResponse !== null && handleClose}>{cancelResponse === null ? 'Canceling' : 'Pending'} withdrawals</DialogCloseTitle>
				<DialogContent>
					{
						cancelResponse === undefined &&
						wds.hasPending() &&
						<Grid container spacing={8} justify="center" alignContent="center">
							<Grid item xs={12}>
								<Typography align="center">
									{
										wds.pendingCount() === 1
											?	'You have one pending withdrawal.'
											: `You have a total of ${wds.pendingCount()} pending withdrawals.`
									}
								</Typography>
							</Grid>
							<Grid item>
								<Button
									onClick={cancelAll}
									color="primary"
									variant="contained"
								>
									Cancel withdrawals
								</Button>
							</Grid>
						</Grid>
					}
					{
						cancelResponse === null &&
						<LoadingIndicator />
					}
					{
						cancelResponse &&
						<Typography align="center">{cancelResponse}</Typography>
					}
				</DialogContent>
			</Dialog>
			{
				player.loggedIn() && wds.hasPending() &&
				<FixedPositionWrapper>
					<Fab
						color="primary"
						aria-label="Pending Withdrawals"
						onClick={() => {
							setDialogOpen(true);
							setCancelResponse(undefined);
						}}
					>
						<AttachMoneyIcon />
					</Fab>
				</FixedPositionWrapper>
			}
		</>
	);
}

PendingWithdrawalsWidget.propTypes = {
	player: PropTypes.object
};

export default subscribeTo(
	{
		player: PlayerContainer,
		wds: PaymentWithdrawalsContainer
	},
	PendingWithdrawalsWidget
);
