import React from 'react';
import { Typography, Paper } from '@material-ui/core';

import { MaxWidth, VerticalPadder } from '../component/styled';
import { Login as LoginComponent, ScrollTopOnMount } from '../component';
import { subscribeTo, PlayerContainer } from '../state';


function Login({ player }) {
	if (player.state.player) {
		return null;
	}

	return (
		<MaxWidth>
			<ScrollTopOnMount />
			<VerticalPadder
				left={2}
				right={2}
			>
				<Typography variant="h3" align="center" component="h1" paragraph>{process.env.REACT_APP_SITE_NAME} Login</Typography>
						<MaxWidth factor={50}>
				<Paper>
					<VerticalPadder
						left={2}
						right={2}
					>
							{!player.state.player && <LoginComponent />}
							{player.state.player && (
								<Typography variant="h5" align="center" component="p" paragraph>You logged in as {player.state.player.username}.</Typography>
							)}
					</VerticalPadder>
				</Paper>
						</MaxWidth>
			</VerticalPadder>
		</MaxWidth>
	);
}

export default subscribeTo(
	{
		player: PlayerContainer,
	},
	Login
);
