import React from 'react';
import styled from 'styled-components/macro';
import { withWidth } from '@material-ui/core';
import { isWidthDown } from '@material-ui/core/withWidth';

import { ResponsiveImageContainer } from '../../component';
import { ApiCarousel } from '.';


const PromoImageContainer = styled(ResponsiveImageContainer)`
	padding: 0 0 63.11239%;
	border-radius: 1em;
`;

const PromoCarousel = withWidth()(({ children, width, ...rest }) => {
	let itemsPerSlide = 3;

	if (isWidthDown('xs', width)) {
		itemsPerSlide = 1;
	} else if (isWidthDown('sm', width)) {
		itemsPerSlide = 2;
	}

	return (
		<ApiCarousel
			itemWrapper={PromoImageContainer}
			itemsPerSlide={itemsPerSlide}
			{...rest}
		/>
	);
});


const ProviderImageContainer = styled(ResponsiveImageContainer)`
	padding: 0 0 67.7777%;
	border: ${p => p.theme.spacing(.5)}px transparent solid;
`;

const ProviderCarousel = withWidth()(({ children, width, ...rest }) => {
	let itemsPerSlide = 5;

	if (isWidthDown('xs', width)) {
		itemsPerSlide = 3;
	}

	return (
		<ApiCarousel
			itemWrapper={ProviderImageContainer}
			itemsPerSlide={itemsPerSlide}
			{...rest}
		/>
	);
});


const PaymentImageContainer = styled(ResponsiveImageContainer)`
	padding: 0 0 62.5%;
	border-radius: .5em;
	border: ${p => p.theme.spacing(.5)}px transparent solid;
`;

const PaymentCarousel = withWidth()(({ children, width, ...rest }) => {
	let itemsPerSlide = 5;

	if (isWidthDown('xs', width)) {
		itemsPerSlide = 4;
	} else if (isWidthDown('sm', width)) {
		itemsPerSlide = 6;
	}

	return (
		<ApiCarousel
			itemWrapper={PaymentImageContainer}
			itemsPerSlide={itemsPerSlide}
			{...rest}
		/>
	);
});


const HeroImageContainer = styled(ResponsiveImageContainer)`
	padding: 0 0 41.66666%;
	border-radius: .5em;
`;

const HeroCarousel = ({ children, ...rest }) => {
	return (
		<ApiCarousel
			itemWrapper={HeroImageContainer}
			{...rest}
		/>
	);
};


const SecondarySliderImageContainer = styled(ResponsiveImageContainer)`
	padding: 0 0 43.8715%;
	border-radius: .5em;
	border: .5em transparent solid;

	${p => p.theme.mui.breakpoints.down('sm')} {
		border-width: 0;
	}
`;

const SecondaryCarousel = withWidth()(({ children, width, ...rest }) => {
	let itemsPerSlide = 2;

	if (isWidthDown('sm', width)) {
		itemsPerSlide = 1;
	}

	return (
		<ApiCarousel
			itemWrapper={SecondarySliderImageContainer}
			itemsPerSlide={itemsPerSlide}
			{...rest}
		/>
	);
});


export {
	PromoCarousel,
	ProviderCarousel,
	PaymentCarousel,
	HeroCarousel,
	SecondaryCarousel
};