import React from 'react';
import qs from 'query-string';

import { MaxWidth, VerticalPadder } from '../component/styled';
import { ResetPassword as ResetPasswordComponent, ScrollTopOnMount } from '../component';


function ResetPassword({ location }) {
	const token = qs.parse(location.search).token || '';

	return (
		<MaxWidth>
			<ScrollTopOnMount />
			<VerticalPadder>
				<ResetPasswordComponent token={token} />
			</VerticalPadder>
		</MaxWidth>
	);
}

export default ResetPassword;
