import React from 'react';
import Helmet from 'react-helmet-async';

import { Promotions as PromotionsComponent } from '../../component/profile';
import { VerticalPadder } from '../../component/styled';
import { siteTitle } from '../../util';


function Restrictions() {
	return (
		<>
			<Helmet>
				<title>{siteTitle('Promotions')}</title>
			</Helmet>
			<VerticalPadder>
				<PromotionsComponent />
			</VerticalPadder>
		</>
	);
}

export default Restrictions;