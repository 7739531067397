import React from 'react';
import {
	Button, TextField, Grid, Typography, Paper
} from '@material-ui/core';

import { Form, Link } from '.';
import { apiFetch, DataValidator, siteTitle } from '../util';
import { VerticalPadder, MaxWidth } from './styled';
import { PlayerContainer, subscribeTo } from '../state';
import Helmet from 'react-helmet-async';


class ResetPassword extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			formError: undefined,
			submitting: false,
			screen: 'initial'
		};

		this.formValidator = new DataValidator({
			password: {
				validators: [ 'required:your new password', 'minLength:8' ]
			},
			password_confirm: {
				validators: [ 'required:your new password', 'sameAs:password:new password' ],
				messages: [ 'Please repeat your new password here.' ]
			}
		});
	}

	componentWillUnmount() {
		this._unmounted = true;
	}

	render() {
		const { token, player } = this.props;

		return (
			<MaxWidth>
			<VerticalPadder left={2} right={2}>
				<Helmet>
					<title>{siteTitle('Password reset')}</title>
				</Helmet>
				<Paper>
					<VerticalPadder>
						<Typography variant="h6" align="center" component="p">Reset account password</Typography>
						{
							player.loggedIn()
								?
									<Typography variant="body2" align="center" component="p" color="error">You are currently logged in. Please log out to reset your password.</Typography>
								:
									(
										!token
											?
												<Typography variant="body2" align="center" component="p" color="error">No token specified.</Typography>
											:
												this.renderForm()
									)
						}
					</VerticalPadder>
				</Paper>
			</VerticalPadder>
			</MaxWidth>
		);
	}

	renderForm() {
		const { formError, submitting, screen } = this.state;

		return (
			<Form
				onSubmit={this.handleSubmit}
				validator={this.formValidator}
				submitting={submitting}
			>
				{formState => (
					<Form.FieldStateProvider
						center
						formState={formState}
						textFieldProps={{
							fullWidth: false,
							helperText: <p>&nbsp;</p>,
							type: 'password'
						}}
					>
						{({ getTextFieldState }) => (
							<VerticalPadder>
								<Grid container spacing={24} justify="center">
									<Grid item>
										<TextField label="New password" {...getTextFieldState('password')} />
									</Grid>
									<Grid item>
										<TextField label="Confirm new password" {...getTextFieldState('password_confirm')} />
									</Grid>
								</Grid>
								{
									formError &&
									<Typography
										paragraph
										align="center"
										color="error"
									>
										{formError}
									</Typography>
								}
								{
									screen === 'confirmation' &&
									<Typography
										paragraph
										align="center"
										color="primary"
									>
										Password changed successfully. You can login now.
									</Typography>
								}
								{
									screen === 'invalidToken' &&
									<Grid container direction="column" alignItems="center">
										<Grid item>
											<Typography
												paragraph
												align="center"
												color="primary"
											>
												Your reset token is invalid or has expired. You can request a new one with the Forgott password form.
											</Typography>
										</Grid>
										<Grid item>
											<Button
												color="primary"
												variant="contained"
												component={Link}
												to="/password-recovery"
											>
												Request new reset token
											</Button>
										</Grid>
									</Grid>
								}
								{
									screen !== 'invalidToken' &&
									<Grid container justify="center" spacing={8}>
										<Grid item>
											<Button
												type="submit"
												variant="contained"
												color="primary"
												disabled={!!formState.submitting}
											>
												{formState.submitting ? 'Changing password...' : 'Reset password'}
											</Button>
										</Grid>
									</Grid>
								}
							</VerticalPadder>
						)}
					</Form.FieldStateProvider>
				)}
			</Form>
		);
	}

	handleSubmit = (data) => {
		data.token = this.props.token;

		this.setState({
			formError: '',
			screen: 'initial',
			submitting: true
		}, () => {
			apiFetch.post('player/resetPassword', {
				data
			})
				.then(this.handleResponse)
				.catch(error => {
					this.handleResponse(error.response);
				});
		});
	};

	handleResponse = response => {
		if (this._unmounted) {
			return;
		}

		let formError = undefined;

		this.setState({
			submitting: false
		});

		if (
			!response ||
			!response.hasOwnProperty('data') ||
			!response.data.hasOwnProperty('info')
		) {
			this.setState({
				screen: 'initial',
				formError: 'Unexpected API error. Please contact support.'
			});

			return;
		}

		if (
			response.data.info.success
		) {
			this.setState({
				screen: 'confirmation'
			});
		} else {
			const { resultCode } = response.data.info;

			switch (resultCode) {
				case 'invalid_token':
					this.setState({
						screen: 'invalidToken'
					});
					break;

				case 'invalid_input':
					formError = 'The password you specified is invalid. Please specify a password at with at least one uppercase letter, one lowercase letter and at least one number.';
					break;

				default:
					formError = 'We\'re having trouble with password recovery at the moment. Please try again in a few...';
					break;
			}
		}

		if (formError) {
			this.setState({
				screen: 'initial',
				formError
			});
		}
	};

}


export default subscribeTo(
	{
		player: PlayerContainer
	},
	ResetPassword
);