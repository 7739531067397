import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import styled from 'styled-components/macro';

function Pagination({ pagination, onPageChange, canonical }) {
	const pages = [];

	for (let page = 1; page <= pagination.maxPages; page++) {
		const isCurrent = pagination.currentPage === page;

		pages.push(
			<Grid key={page} item>
				<Button
					onClick={ev => {
						ev.preventDefault();
						onPageChange(page);
					}}
					color={isCurrent ? 'primary' : undefined}
					variant={isCurrent ? 'contained' : 'outlined'}
					size="small"
					mini
					component={Link}
					to={typeof canonical === 'function' ? canonical(page) : `/${canonical}/${page}`}
				>
					{page}
				</Button>
			</Grid>
		);
	}

	return (
		<Grid container direction="row" alignItems="center" spacing={8}>
			<Grid item>
				<Button
					disabled={!pagination.hasPreviousPage}
					onClick={() => onPageChange(pagination.previousPage)}
					variant="outlined"
					size="small"
				>
					<ChevronLeftIcon />
				</Button>
			</Grid>
			<StretchGrid item container justify="center" spacing={8}>
				{pages}
			</StretchGrid>
			<Grid item>
				<Button
					disabled={!pagination.hasNextPage}
					onClick={() => onPageChange(pagination.nextPage)}
					variant="outlined"
					size="small"
				>
					<ChevronRightIcon />
				</Button>
			</Grid>
		</Grid>
	);

}

Pagination.propTypes = {
	content: PropTypes.object,
	detailsItemComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object])
};

Pagination.defaultProps = {
};


const StretchGrid = styled(Grid)`
	flex: 1;
`


export default Pagination;