import React from 'react';

import { ApiContentLoader } from '../component/api';
import { VerticalPadder, MaxWidth, PageWidthBoundary } from '../component/styled';
import { ScrollTopOnMount } from '../component';
import { subscribeTo, AppStateContainer } from '../state';


function Affiliates({ app }) {
	return (
		<PageWidthBoundary>
			<VerticalPadder>
				<ScrollTopOnMount />
				<MaxWidth>
					<ApiContentLoader
						type={app.state.auOnly ? 'footer,aff' : 'affiliates-v1'}
						setMeta
						canonicalUrl="content/affiliates"
						showContentTitle
					/>
				</MaxWidth>
			</VerticalPadder>
		</PageWidthBoundary>
	);
}

export default subscribeTo(
	{
		app: AppStateContainer
	},
	Affiliates
);
