import styled from 'styled-components/macro';


const PageWidthBoundary = styled.div`
	display: block;
	max-width: ${p => p.theme.spacing(176)}px;
	margin: 0 auto;
	padding: 0 ${p => p.theme.spacing()}px;
`;

export default PageWidthBoundary;