import React from 'react';

import { PaymentProcess } from '.';


function Deposit() {
	return (
		<PaymentProcess methodType="deposit" />
	)
}

export default Deposit;