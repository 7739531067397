import React from 'react';

import { VirtualSports as VirtualSportsComponent } from '../component';
//import { apiFetch } from '../util';


class VirtualSports extends React.PureComponent {

	render() {
		//apiFetch(`games/providers/virtual-sports/true`);
		//apiFetch(`games/filter/all/_kiron/nosort/10/0`);
		return (
			<VirtualSportsComponent />
		);
	}

}

export default VirtualSports;
