import React from 'react';
import { Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ApiContentLoader, ApiContent } from '../../component/api';
import { VerticalPadder, MaxWidth, PageWidthBoundary } from '../../component/styled';


class HowToPlay extends React.PureComponent {

	render() {
		return (
			<PageWidthBoundary>
				<VerticalPadder>
					<MaxWidth>
						<ApiContentLoader
							type="footer,popup"
							component={List}
							setMeta
							canonical="content/how_to_play"
							showContentTitle
							contentTitleComponent="h1"
						/>
					</MaxWidth>
				</VerticalPadder>
			</PageWidthBoundary>
		);
	}

}


function sortByTitle(a, b) {
	if (a.props.title < b.props.title) {
		return -1;
	}

	if (a.props.title > b.props.title) {
		return 1;
	}

	return 0;
}

const List = React.memo(({ content }) => {
	let liveCasinos = [];
	const casinos = [];

	content.children.forEach(item => {
		const titleParts = item.title.split('_');

		if (titleParts[0] === 'Casino Live') {
			liveCasinos.push(<ListItem key={titleParts[1]} title={titleParts[1]} content={item.content} />);
		} else {
			casinos.push(<ListItem key={titleParts[1]} title={titleParts[1]} content={item.content} />);
		}
	});

	liveCasinos = liveCasinos.sort(sortByTitle);
	casinos.sort(sortByTitle);

	return (
		<>
			<VerticalPadder>
				<Typography variant="h6" component="h2">Casino live</Typography>
				{liveCasinos}
			</VerticalPadder>
			<VerticalPadder>
				<Typography variant="h6" component="h2">Casino</Typography>
				{casinos}
			</VerticalPadder>
		</>
	);
});


const ListItem = React.memo(({ title, content }) => (
	<ExpansionPanel>
		<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
			<Typography component="h3">{title}</Typography>
		</ExpansionPanelSummary>
		<ExpansionPanelDetails>
			<ApiContent>
				{content}
			</ApiContent>
		</ExpansionPanelDetails>
	</ExpansionPanel>
));

export default HowToPlay;
