import { BaseContainer } from '.';
import { apiFetch } from '../util';


class PaymentWithdrawalsContainer extends BaseContainer {

	static initialState() {
		return {
			transactions: undefined,
			amountStr: '',
			amount: 0,
			lastModified: new Date()
		};
	}

	static fetch() {
		return apiFetch('player/transactions/pendingWithdrawals');
	}

	loaded() {
		return this.state.transactions !== undefined;
	}

	errorResponse() {
		return this.state.transactions === null;
	}

	valuesLoaded() {
		return this.loaded() && !this.errorResponse();
	}

	hasPending() {
		return this.pendingCount() > 0;
	}

	pendingCount() {
		const { transactions } = this.state;

		if (Array.isArray(transactions)) {
			return transactions.length;
		}

		return 0;
	}

	handleResponse = response => {
		if (
			!response ||
			!response.info ||
			!response.info.success ||
			!response.data ||
			!Array.isArray(response.data.trans_ids)
		) {
			this.setState({
				transactions: null,
				amountStr: '',
				amount: 0,
				lastModified: new Date()
			});

			return;
		}

		this.setState({
			transactions: response.data.trans_ids,
			amountStr: response.data.pendingWithdrawalAmountStr || '',
			amount: response.data.pendingWithdrawalAmountVal || 0,
			lastModified: new Date()
		});
	};

	fetchIfOld = () => {
		const timeDiff = new Date() - this.state.lastModified;

		if (timeDiff > 60000) {
			this.fetch(true);
		}
	};

}

export default PaymentWithdrawalsContainer;