import React from 'react';
import { scrollToTop } from '../util';


class ScrollTopOnMount extends React.Component {

	componentDidMount() {
		scrollToTop();
	}

	shouldComponentUpdate() {
		return false;
	}

	render() {
		return null;
	}

}

export default ScrollTopOnMount;
