import React from 'react';

import { ApiContentLoader, SecondaryCarousel, ApiSeoContent } from '../component/api';
import { VerticalPadder, PageWidthBoundary } from '../component/styled';
import { AppStateContainer, subscribeTo } from '../state';

const BitcoinCasino = ({ app }) => (
	<>
		<VerticalPadder top={0}>
			<ApiContentLoader
				type={app.state.auOnly ? 'bitcoincasino-hero-v2' : 'hero-banner-bitcoin-casino'}
			/>
		</VerticalPadder>
		<PageWidthBoundary>
			<VerticalPadder>
				<ApiContentLoader
					type="hero-content-slider"
					component={SecondaryCarousel}
				/>
			</VerticalPadder>
			<VerticalPadder>
				<ApiContentLoader
					type={app.state.auOnly ? 'bitcoincasino-seo-v2' : 'bitcoin-casino-seo-new'}
					component={ApiSeoContent}
					setMeta
					canonicalUrl="bitcoin-casino"
				/>
			</VerticalPadder>
		</PageWidthBoundary>
	</>
);

export default subscribeTo(
	{
		app: AppStateContainer
	},
	BitcoinCasino
);
