import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
	AppStateContainer, subscribeTo
} from '../state';


function Link({ app, to, _renderIndex, ...rest }) {
	//to = app.url(to);

	return (
		<RouterLink to={to} {...rest} />
	);
}

export default subscribeTo(
	{
		app: AppStateContainer
	},
	Link
);
