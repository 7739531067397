import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet-async';

import { ApiContentLoader } from '../component/api';
import { VerticalPadder, PageWidthBoundary } from '../component/styled';
import { ScrollTopOnMount } from '../component';
import { AppStateContainer, subscribeTo } from '../state';

const paymentOptions = [
	'bitcoin',
	'myneosurf',
	'neosurf'
];

const PaymentOptions = ({ app }) => (
	<Switch>
		{paymentOptions.map(option => (
			<Route key={option} path={`/payment-options/${option}`}>
				<>
					<ScrollTopOnMount key={option} />
					<VerticalPadder top={0}>
						<ApiContentLoader
							type={`${option}-hero`}
						/>
					</VerticalPadder>
					<PageWidthBoundary>
						<VerticalPadder>
							<ApiContentLoader
								type={`${option}-seo`}
								setMeta
							/>
						</VerticalPadder>
					</PageWidthBoundary>
				<Helmet>
					<link rel="canonical" href={app.canonicalize(`/payment-options/${option}`)} />
				</Helmet>
				</>
			</Route>
		))}
		<Route path="/payment-options">
			<>
				<ScrollTopOnMount key="index" />
				<VerticalPadder top={0}>
					<ApiContentLoader
						type={app.state.auOnly ? 'payment-hero' : 'payment-hero-v1'}
					/>
				</VerticalPadder>
				<PageWidthBoundary>
					<VerticalPadder>
						<ApiContentLoader
							type={app.state.auOnly ? 'payments-seo-v2' : 'payment-seo-v1'}
							setMeta
						/>
					</VerticalPadder>
				</PageWidthBoundary>
				<Helmet>
					<link rel="canonical" href={app.canonicalize('payment-options')} />
				</Helmet>
			</>
		</Route>
	</Switch>
);

export default subscribeTo(
	{
		app: AppStateContainer
	},
	PaymentOptions
);
